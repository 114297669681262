import axios from "axios";
import {decodeBase64} from "@/util";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');

export const reloadHeader = () => {
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
};

export const getMaintenanceReason = (reason = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchMaintenanceReason(reason_Icontains: "${reason}"){
                        edges{
                            node {
                                id
                                active
                                reason
                            }
                        }
                    }
                }`
    })
}

export const getAutos = (next = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `
        {
            searchAutosAvailableMaintenances(isActive: true, after: "${next}", estado: "Disponible"){
                pageInfo{
                    hasNextPage
                    hasNextPage
                }
                edges{
                    node{
                        id
                        color
                        categoria{
                                imagen
                        }
                        modelo{
                            name
                            marca{
                                name
                            }
                        }
                    }
                }
            }
        }
    `
    });
}

export const createMaintenance = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($maintenance: CreateMaintenanceSerializer!){
                   createMaintenance(obj: $maintenance){
                    info {
                        id
                        auto{
                            id
                            noEconomico
                            noSerie
                            color
                            valor
                            seguro
                            procedencia
                            fechaSeguro
                            fechaGps
                            fechaCirculacion
                            noPlaca
                            modelo {
                                name
                                marca {
                                    name
                                }
                            }
                            categoria {
                                name
                                imagen
                                transmision
                                descripcion
                            }
                        }
                        kmIn
                        kmOut
                        gasolineIn
                        gasolineOut
                        maintenanceReason{
                            id
                            reason
                        }
                        dateIn
                        dateOut
                        dateOutPlan
                        finishMaintenance
                        typeCarWorkshop
                        details
                    }
                  }
                 }`, variables: {maintenance: obj}
    })
}

export const updateMaintenance = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($maintenance: UpdateMaintenanceSerializer!){
                   updateMaintenance(obj: $maintenance){
                    info {
                        id
                        auto{
                            id
                            noEconomico
                            noSerie
                            color
                            valor
                            seguro
                            procedencia
                            fechaSeguro
                            fechaGps
                            fechaCirculacion
                            noPlaca
                            modelo {
                                name
                                marca {
                                    name
                                }
                            }
                            categoria {
                                name
                                imagen
                                transmision
                                descripcion
                            }
                        }
                        kmIn
                        kmOut
                        gasolineIn
                        gasolineOut
                        maintenanceReason{
                            id
                            reason
                        }
                        dateIn
                        dateOut
                        dateOutPlan
                        finishMaintenance
                        typeCarWorkshop
                        details
                    }
                  }
                 }`, variables: {maintenance: obj}
    })
}

export const createPreInvoice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($preInvoice: PreInvoiceCreateSerializer!){
                   createPreInvoice(obj: $preInvoice){
                    info {
                        id
                        iva
                        dateOfIssue
                        description
                        moneyType
                        paymentForm
                        paymentMethod
                        subtotal
                        total
                        contract{
                            id
                            contratoNo
                            cliente{
                                nombre
                                apellidos
                            }
                        }
                    }
                  }
                 }`, variables: {preInvoice: obj}
    })
}

export const updatePreInvoice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($preInvoice: PreInvoiceUpdateSerializer!){
                   updatePreInvoice(obj: $preInvoice){
                    info {
                        id
                        iva
                        dateOfIssue
                        description
                        moneyType
                        paymentForm
                        paymentMethod
                        subtotal
                        total
                        contract{
                            id
                            contratoNo
                            cliente{
                                nombre
                                apellidos
                            }
                        }
                    }
                  }
                 }`, variables: {preInvoice: obj}
    })
}

export const deletePreInvoice = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                    deletePreInvoice(id: $id){
                        ok
                    }
                }`, variables: {id: id}
    })
}

export const getMaintenance = (before, next, model = "", marca = "", color = "", reason = "", cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchMaintenances(
                        first:${cant}, 
                        before: "${before}", 
                        after: "${next}",
                        auto_Modelo_Marca_Name_Icontains: "${marca}",
                        auto_Modelo_Name_Icontains: "${model}",
                        auto_Color_Icontains: "${color}",
                        maintenanceReason_Reason_Icontains: "${reason}"
                     ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                auto{
                                    id
                                    noEconomico
                                    noSerie
                                    color
                                    valor
                                    seguro
                                    procedencia
                                    fechaSeguro
                                    fechaGps
                                    fechaCirculacion
                                    noPlaca
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                    categoria {
                                        name
                                        imagen
                                        transmision
                                        descripcion
                                    }
                                }
                                kmIn
                                kmOut
                                gasolineIn
                                gasolineOut
                                maintenanceReason{
                                    id
                                    reason
                                }
                                dateIn
                                dateOut
                                dateOutPlan
                                finishMaintenance
                                typeCarWorkshop
                                details
                            }
                        }
                    }
                }`
        }
    );
};

export const getPrevMaintenance = (before, model = "", marca = "", color = "", reason = "", cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchMaintenances(
                        first:${cant}, 
                        before: "${before}", 
                        auto_Modelo_Marca_Name_Icontains: "${marca}",
                        auto_Modelo_Name_Icontains: "${model}",
                        auto_Color_Icontains: "${color}",
                        maintenanceReason_Reason_Icontains: "${reason}"
                    ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                auto{
                                    id
                                    noEconomico
                                    noSerie
                                    color
                                    valor
                                    seguro
                                    procedencia
                                    fechaSeguro
                                    fechaGps
                                    fechaCirculacion
                                    noPlaca
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                    categoria {
                                        name
                                        imagen
                                        transmision
                                        descripcion
                                    }
                                }
                                kmIn
                                kmOut
                                gasolineIn
                                gasolineOut
                                maintenanceReason{
                                    id
                                    reason
                                }
                                dateIn
                                dateOut
                                dateOutPlan
                                finishMaintenance
                                typeCarWorkshop
                                details
                            }
                        }
                    }
                }`
        }
    );
};

export const getNextMaintenance = (next, model = "", marca = "", color = "", reason = "", cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchMaintenances(
                        first:${cant}, 
                        after: "${next}", 
                        auto_Modelo_Marca_Name_Icontains: "${marca}",
                        auto_Modelo_Name_Icontains: "${model}",
                        auto_Color_Icontains: "${color}",
                        maintenanceReason_Reason_Icontains: "${reason}"
                    ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                auto{
                                    id
                                    noEconomico
                                    noSerie
                                    color
                                    valor
                                    seguro
                                    procedencia
                                    fechaSeguro
                                    fechaGps
                                    fechaCirculacion
                                    noPlaca
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                    categoria {
                                        name
                                        imagen
                                        transmision
                                        descripcion
                                    }
                                }
                                kmIn
                                kmOut
                                gasolineIn
                                gasolineOut
                                maintenanceReason{
                                    id
                                    reason
                                }
                                dateIn
                                dateOut
                                dateOutPlan
                                finishMaintenance
                                typeCarWorkshop
                                details
                            }
                        }
                    }
                }`
        }
    );
};

export const getPreInvoice = (before, next, clientName = "", clientLastname = "", total = 0, subtotal = 0, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchPreInvoices(
                        first:${cant}, 
                        before: "${before}", 
                        after: "${next}",
                        contract_Cliente_Nombre_Icontains: "${clientName}",
                        contract_Cliente_Apellidos_Icontains: "${clientLastname}",
                        total_Gte: ${total},
                        subtotal_Gte: ${subtotal},
                     ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                iva
                                dateOfIssue
                                description
                                moneyType
                                paymentForm
                                paymentMethod
                                subtotal
                                total
                                contract{
                                    id
                                    contratoNo
                                    cliente{
                                        nombre
                                        apellidos
                                    }
                                }
                            }
                        }
                    }
                }`
        }
    );
};

export const getPrevPreInvoice = (before, clientName = "", clientLastname = "", total = 0, subtotal = 0, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchPreInvoices(
                        first:${cant}, 
                        before: "${before}", 
                        contract_Cliente_Nombre_Icontains: "${clientName}",
                        contract_Cliente_Apellidos_Icontains: "${clientLastname}",
                        total_Gte: ${total},
                        subtotal_Gte: ${subtotal},
                    ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                iva
                                dateOfIssue
                                description
                                moneyType
                                paymentForm
                                paymentMethod
                                subtotal
                                total
                                contract{
                                    id
                                    contratoNo
                                    cliente{
                                        nombre
                                        apellidos
                                    }
                                }
                            }
                        }
                    }
                }`
        }
    );
};

export const getNextPreInvoice = (next, clientName = "", clientLastname = "", total = 0, subtotal = 0, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchPreInvoices(
                        first:${cant}, 
                        after: "${next}", 
                        contract_Cliente_Nombre_Icontains: "${clientName}",
                        contract_Cliente_Apellidos_Icontains: "${clientLastname}",
                        total_Gte: ${total},
                        subtotal_Gte: ${subtotal},
                    ){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                iva
                                dateOfIssue
                                description
                                moneyType
                                paymentForm
                                paymentMethod
                                subtotal
                                total
                                contract{
                                    id
                                    contratoNo
                                    cliente{
                                        nombre
                                        apellidos
                                    }
                                }
                            }
                        }
                    }
                }`
        }
    );
};

export const createMaintenanceReason = (reason) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($reason:String!){
                   createMaintenanceReason(obj:{
                       reason:$reason
                    }){
                    info {
                        id
                        reason
                        active
                    }
                  }
                 }`, variables: {reason: reason}
    })
}

export const updateMaintenanceReason = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!, $reason:String!){
                   updateMaintenanceReason(obj:{
                        id: $id,
                        reason:$reason
                    }){
                    info {
                        id
                        reason
                        active
                    }
                  }
                 }`, variables: obj
    })
}

export const deleteMaintenance = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   deleteMaintenance(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const changeMaintenance = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($obj: ChangeMaintenanceSerializer!){
                   changeMaintenance(obj: $obj){
                      ok
                   }
                 }`, variables: {obj: obj}
    })
}

export const deleteMaintenanceReason = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   deleteMaintenanceReason(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const changeStateMaintenanceReason = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   changeStateOfMaintenanceReason(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const changeStateMaintenance = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   changeStateOfMaintenance(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const getCarWorkshops = (before, next, name, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchCarWorkshops(first:${cant}, before: "${before}", after: "${next}", name_Icontains: "${name}"){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                name
                                typeCarWorkshop
                                active
                            }
                        }
                    }
                }`
        }
    );
};

export const getPrevCarWorkshops = (before, name, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchCarWorkshops(first:${cant}, before: "${before}", name_Icontains: "${name}"){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                name
                                typeCarWorkshop
                                active
                            }
                        }
                    }
                }`
        }
    );
};

export const getNextCarWorkshops = (next, name, cant = 10) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
        {
            query: `{
                    searchCarWorkshops(first:${10}, after: "${next}", name_Icontains: "${name}"){
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                name
                                typeCarWorkshop
                                active
                            }
                        }
                    }
                }`
        }
    );
};

export const getContractsPreInvoice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchContractsDefault(
                        preinvoice_Isnull: true,
                        estado: "Contratado"
                    ){
                        edges{
                            node{
                                id
                                contratoNo
                                estado
                                cliente{
                                    nombre
                                    apellidos
                                }
                            }
                        }
                    }
                }`
    })
}

export const createCarWorkshop = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($name:String!, $typeCarWorkshop:String!){
                   createCarWorkshop(obj:{
                       name:$name,
                       typeWorkshop:$typeCarWorkshop
                   }){
                        info {
                            id
                            name
                            typeCarWorkshop
                            active
                        }
                  }
                 }`, variables: obj
    })
}

export const updateCarWorkshop = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!, $name:String!, $typeCarWorkshop:String!){
                   updateCarWorkshop(obj:{
                        id: $id,
                        name:$name,
                        typeWorkshop:$typeCarWorkshop
                   }){
                        info {
                            id
                            name
                            typeCarWorkshop
                            active
                        }
                  }
                 }`, variables: obj
    })
}

export const deleteCarWorkshop = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   deleteCarWorkshop(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const changeStateCarWorkshop = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` mutation($id: String!){
                   changeStateCarWorkshop(id: $id){
                      ok
                   }
                 }`, variables: obj
    })
}

export const searchContracts = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchContratos(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                    $estado:String!
                ) {
                    searchContracts(
                        name: $name
                        before: $before
                        after: $after
                        first: $first
                        estado_Icontains:$estado
                    ) {
                        edges {
                            node {
                                id
                                contratoNo
                                invoiced
                                createdBy{
                                email
                                }
                                total
                                granTotal
                                dias
                                dailyPrice
                                totalServicios
                                totalProteccion
                                totalDropoff
                                contratoSucursalRecogida{
                                ciudad{
                                iva
                                }
                                }
                                fechaSalida
                                fechaRegreso
                                estado
                                cliente {
                                    id
                                    user {
                                        email
                                    }
                                }
                                procedencia {
                                    nombre
                                }
                                vehiculo {
                                    categoria {
                                        name
                                    }
                                }
                                invoice{
                                    xmlFile
                                    pdfFile
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}
export const searchPrevContracts = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchContratos(
                    $name: String!
                    $before: String!
                    $first: Int!
                    $estado:String!
                ) {
                    searchContracts(
                        name: $name
                        before: $before
                        first: $first
                        estado_Icontains:$estado
                    ) {
                        edges {
                            node {
                                id
                                createdBy{
                                email
                                }
                                contratoNo
                                invoiced
                                total
                                granTotal
                                fechaSalida
                                fechaRegreso
                                estado
                                cliente {
                                    id
                                    user {
                                        email
                                    }
                                }
                                procedencia {
                                    nombre
                                }
                                vehiculo {
                                    categoria {
                                        name
                                    }
                                }
                                invoice{
                                    xmlFile
                                    pdfFile
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}
export const searchNextContracts = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchContratos(
                    $name: String!
                    $after:String!
                    $first:Int!
                    $estado:String!
                    ) {
                    searchContracts(
                        name: $name
                        after: $after
                        first: $first
                        estado_Icontains:$estado
                    ) {
                        edges {
                            node {
                                id
                                createdBy{
                                email
                                }
                                contratoNo
                                invoiced
                                total
                                granTotal
                                estado
                                fechaSalida
                                fechaRegreso
                                cliente {
                                    id
                                    user {
                                        email
                                    }
                                }
                                procedencia {
                                    nombre
                                }
                                vehiculo {
                                    categoria {
                                        name
                                    }
                                }
                                invoice{
                                    xmlFile
                                    pdfFile
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}


export const searchReserves = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchReserves(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                ) {
                    searchReserves(
                        name: $name
                        before: $before
                        after: $after
                        first: $first
                    ) {
                        edges {
                            cursor
                            node {
                                id
                                reservaNo
                                comentario
                                fechaSalida
                                fechaRegreso
                                fecha
                                dias
                                total
                                totalServicios
                                totalProteccion
                                totalDropoff
                                totalPromocion
                                promocion {
                                    id
                                    nombre
                                    imagen
                                    codigo
                                    fechaInicio
                                    fechaFin
                                    descripcion
                                    porciento
                                }
                                montoAPagar
                                estado
                                priceExtra
                                isPriceExtra
                                montoAPagar
                                createdBy {
                                email
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    isActive
                                    rfc
                                    user {
                                        email
                                    }
                                    direccionSet {
                                        edges {
                                            node {
                                                ciudad
                                                estado
                                                pais
                                                direccion
                                            }
                                        }
                                    }
                                    licenciaSet {
                                        edges {
                                            node {
                                                numero
                                                vencimiento
                                            }
                                        }
                                    }
                                    contactoSet {
                                        edges {
                                            node {
                                                telefono
                                                codigo
                                            }
                                        }
                                    }
                                    tarjetaSet {
                                        edges {
                                            node {
                                                vencimiento
                                                numero
                                            }
                                        }
                                    }
                                }
                                reservaSucursalRecogida {
                                    id
                                    name
                                }
                                reservaSucursalEntrega {
                                    id
                                    name
                                }
                                categoria {
                                    id
                                    name
                                    imagen
                                    tarifaAlta
                                    tarifaBaja
                                }
                                proteccion {
                                    id
                                    name
                                    descripcion
                                    tarifa
                                }
                                metodoPago
                                procedencia {
                                    id
                                    nombre
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}
export const searchReservesDate = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchReservesDate(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                    $date: DateTime!
                ) {
                    searchReservesDate(
                        name: $name
                        before: $before
                        after: $after
                        first: $first
                        date: $date
                    ) {
                        edges {
                            cursor
                            node {
                                id
                                reservaNo
                                comentario
                                fechaSalida
                                fechaRegreso
                                fecha
                                dias
                                total
                                totalServicios
                                totalProteccion
                                totalDropoff
                                montoAPagar
                                totalPromocion
                                promocion {
                                    id
                                    nombre
                                    imagen
                                    codigo
                                    fechaInicio
                                    fechaFin
                                    descripcion
                                    porciento
                                }
                                estado
                                priceExtra
                                isPriceExtra
                                montoAPagar
                                createdBy {
                                email
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    rfc
                                    contactoSet {
                                      edges {
                                        node {
                                            telefono
                                        }
                                      }
                                    }
                                    user {
                                        id
                                        email
                                    }
                                }
                                reservaSucursalRecogida {
                                    id
                                    name
                                }
                                reservaSucursalEntrega {
                                    id
                                    name
                                }
                                categoria {
                                    id
                                    name
                                    imagen
                                    tarifaAlta
                                    tarifaBaja
                                }
                                proteccion {
                                    id
                                    name
                                    descripcion
                                    tarifa
                                }
                                metodoPago
                                procedencia {
                                    id
                                    nombre
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}
export const searchPrevReserves = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchReserves(
                    $name: String!
                    $before: String!
                    $first: Int!
                ) {
                    searchReserves(
                        name: $name
                        before: $before
                        first: $first
                    ) {
                        edges {
                            cursor
                            node {
                                id
                                reservaNo
                                comentario
                                fechaSalida
                                fechaRegreso
                                fecha
                                dias
                                total
                                createdBy {
                                email
                                }
                                totalServicios
                                totalProteccion
                                totalDropoff
                                montoAPagar
                                totalPromocion
                                promocion {
                                    id
                                    nombre
                                    imagen
                                    codigo
                                    fechaInicio
                                    fechaFin
                                    descripcion
                                    porciento
                                }
                                estado
                                priceExtra
                                isPriceExtra
                                montoAPagar
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    isActive
                                    rfc
                                    user {
                                        email
                                    }
                                    direccionSet {
                                        edges {
                                            node {
                                                ciudad
                                                estado
                                                pais
                                                direccion
                                            }
                                        }
                                    }
                                    licenciaSet {
                                        edges {
                                            node {
                                                numero
                                                vencimiento
                                            }
                                        }
                                    }
                                    contactoSet {
                                        edges {
                                            node {
                                                telefono
                                                codigo
                                            }
                                        }
                                    }
                                    tarjetaSet {
                                        edges {
                                            node {
                                                vencimiento
                                                numero
                                            }
                                        }
                                    }
                                }
                                reservaSucursalRecogida {
                                    id
                                    name
                                }
                                reservaSucursalEntrega {
                                    id
                                    name
                                }
                                categoria {
                                    id
                                    imagen
                                    name
                                    tarifaAlta
                                    tarifaBaja
                                }
                                proteccion {
                                    id
                                    name
                                    descripcion
                                    tarifa
                                }
                                metodoPago
                                procedencia {
                                    id
                                    nombre
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}
export const searchNextReserves = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchReserves(
                    $name: String!
                    $after:String!
                    $first:Int!
                    ) {
                    searchReserves(
                        name: $name
                        after: $after
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                reservaNo
                                comentario
                                fechaSalida
                                fechaRegreso
                                fecha
                                dias
                                createdBy {
                                email
                                }
                                total
                                totalServicios
                                totalProteccion
                                totalDropoff
                                montoAPagar
                                totalPromocion
                                promocion {
                                    id
                                    nombre
                                    imagen
                                    codigo
                                    fechaInicio
                                    fechaFin
                                    descripcion
                                    porciento
                                }
                                estado
                                priceExtra
                                isPriceExtra
                                montoAPagar
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    isActive
                                    rfc
                                    user {
                                        email
                                    }
                                    direccionSet {
                                        edges {
                                            node {
                                                ciudad
                                                estado
                                                pais
                                                direccion
                                            }
                                        }
                                    }
                                    licenciaSet {
                                        edges {
                                            node {
                                                numero
                                                vencimiento
                                            }
                                        }
                                    }
                                    contactoSet {
                                        edges {
                                            node {
                                                telefono
                                                codigo
                                            }
                                        }
                                    }
                                    tarjetaSet {
                                        edges {
                                            node {
                                                vencimiento
                                                numero
                                            }
                                        }
                                    }
                                }
                                reservaSucursalRecogida {
                                    id
                                    name
                                }
                                reservaSucursalEntrega {
                                    id
                                    name
                                }
                                categoria {
                                    id
                                    name
                                    imagen
                                    tarifaAlta
                                    tarifaBaja
                                }
                                proteccion {
                                    id
                                    name
                                    descripcion
                                    tarifa
                                }
                                metodoPago
                                procedencia {
                                    id
                                    nombre
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}


export const searchClients = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchClients(
                    $name: String!
                    $before: String!
                    $after: String!
                    $cant: Int!
                ) {
                    searchClients(first: $cant, before: $before, after: $after, name: $name) {
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                nombre
                                puntos
                                apellidos
                                isActive
                                photo
                                photoAdmin
                                user {
                                    id
                                    email
                                }
                            }
                        }
                    }
                }`, variables: obj
    })
}
export const searchPrevClients = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchClients(
                    $name: String!
                    $before: String!
                    $cant: Int!
                ) {
                    searchClients(
                        name: $name
                        before: $before
                        first: $cant
                    ) {
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                nombre
                                puntos
                                apellidos
                                isActive
                                photo
                                photoAdmin
                                user {
                                    id
                                    email
                                }
                            }
                        }
                    }
                }`, variables: obj
    });
}
export const searchNextClients = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchClients(
                    $name: String!
                    $after:String!
                    $cant:Int!
                    ) {
                    searchClients(
                        name: $name
                        after: $after
                        first: $cant
                    ) {
                        pageInfo{
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges{
                            node{
                                id
                                nombre
                                puntos
                                apellidos
                                isActive
                                photo
                                photoAdmin
                                user {
                                    id
                                    email
                                }
                            }
                        }
                    }
                }`, variables: obj
    })
}
export const searchClientById = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` {
                        searchClientById(id: "${id}"){
                            id
                            tratamiento
                            nombre
                            apellidos
                            rfc
                            nacionalidad
                            nacimiento
                            isActive
                            photo
                            photoAdmin
                            user {
                                id
                                email
                            }
                            direccionSet(first: 1) {
                                edges{
                                    node{
                                        id
                                        direccion
                                        postal
                                        ciudad
                                        estado
                                        pais
                                    }
                                }
                            }
                            identidadSet(first: 1) {
                                edges{
                                    node{
                                        id
                                        tipo
                                        numero
                                        expedicion
                                        imagenFront
                                        imagenBack
                                        pais
                                    }
                                }
                            }
                            licenciaSet(first: 1) {
                                edges{
                                    node{
                                        id
                                        numero
                                        clase
                                        otorgamiento
                                        vencimiento
                                        imagenFront
                                        imagenBack
                                    }
                                }
                            }
                            contactoSet(first: 1) {
                                edges{
                                    node{
                                        id
                                        telefono
                                        codigo
                                        idioma
                                    }
                                }
                            }
                            tarjetaSet(first: 1) {
                                edges{
                                    node{
                                        id
                                        numero
                                        vencimiento
                                        autorizacion
                                        imagenFront
                                        imagenBack
                                    }
                                }
                            }
                        }
                    }`
    })
}

export const searchCar = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchAutos(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                ) {
                    searchAutos(
                        name: $name
                        before: $before
                        after: $after
                        first: $first
                    ) {
                        edges {
                            cursor
                            node {
                                 id
                                noEconomico
                                estado
                                noPlaca
                                kilometraje
                                kmService
                                kmControl
                                estado
                                isActive
                                sucursal {
                                    id
                                    name
                                    ciudad {
                                        id
                                        name
                                        iva
                                    }
                                }
                                modelo {
                                    id
                                    name
                                    marca {
                                        id
                                        name
                                    }
                                }
                                categoria {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const getCountriesByName = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `
                query PaisesByName {
                    paisesByName(name: "${name}") {
                        id
                        name
                        abreviatura
                        isActive
                    }
                }`
    });
}

export const getCitiesByName = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CiudadesByName {
                    ciudadesByName(name: "${name}") {
                        id
                        name
                        siglas
                        iva
                        isActive
                        estado {
                            id
                            name
                        }
                    }
                }`
    });
}

export const getStatesByName = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query EstadosByName {
                    estadosByName(name: "") {
                        id
                        name
                        pais{
                            id
                            name
                        }
                        abreviatura
                        isActive
                    }
                }`
    });
}

export const searchPrevCar = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchAutos(
                    $name: String!
                    $before: String!
                    $first: Int!
                ) {
                    searchAutos(
                        name: $name
                        before: $before
                        first: $first
                    ) {
                        edges {
                            cursor
                            node {
                                 id
                                noEconomico
                                estado
                                noPlaca
                                isActive
                                estado
                                kilometraje
                                kmService
                                kmControl
                                sucursal {
                                    id
                                    name
                                    ciudad {
                                        id
                                        name
                                        iva
                                    }
                                }
                                modelo {
                                    id
                                    name
                                    marca {
                                        id
                                        name
                                    }
                                }
                                categoria {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchNextCar = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchAutos(
                    $name: String!
                    $after:String!
                    $first:Int!
                    ) {
                    searchAutos(
                        name: $name
                        after: $after
                        first: $first
                    ) {
                        edges {
                            cursor
                            node {
                                 id
                                noEconomico
                                estado
                                isActive
                                noPlaca
                                kilometraje
                                kmService
                                kmControl
                                estado
                                sucursal {
                                    id
                                    name
                                    ciudad {
                                        id
                                        name
                                        iva
                                    }
                                }
                                modelo {
                                    id
                                    name
                                    marca {
                                        id
                                        name
                                    }
                                }
                                categoria {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const getMarcaModel = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query MarcasByName {
                    marcasByName(name: "") {
                        id
                        name
                        isActive
                        modeloSet{
                            edges{
                                node{
                                    id
                                    name
                                    isActive
                                }
                            }
                        }
                    }
                }`
    })
}

export const getAutoById = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{ searchAutoById(
                        id: "${id}"
                    ) {
                        id
                        noEconomico
                        noSerie
                        color
                        valor
                        seguro
                        procedencia
                        fechaSeguro
                        fechaGps
                        fechaCirculacion
                        isActive
                        noPlaca
                        sucursal{
                        id
                        name
                        ciudad{
                        id
                        name
                        }
                        }
                        modelo {
                            id
                            name
                            isActive
                            marca {
                                id
                                name
                                isActive
                            }
                        }
                        categoria {
                            id
                            name
                            isActive
                            imagen
                            transmision
                            descripcion
                        }
                    }
                }`
    });
}


export const searchCategoryByName = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    categoriasByName(name: "${name}") {
                        id
                        name
                        isActive
                    }
                }`
    });
}

export const createAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateVehiculo($categoria:Int!, $color:String!,$fechaCirculacion:Date!, $fechaGps:Date!,
                     $fechaSeguro:Date!, $modelo:Int!,  $noEconomico:String!, $noSerie:String!,
                      $noPlaca:String!, $procedencia:String!, $seguro:String!, $valor:Decimal!, $sucursal:ID! ) {
                        createVehiculo(
                            categoria: $categoria
                            color: $color
                            fechaCirculacion: $fechaCirculacion
                            fechaGps: $fechaGps
                            fechaSeguro: $fechaSeguro
                            modelo: $modelo
                            noEconomico: $noEconomico
                            noSerie:$noSerie
                            noPlaca:$noPlaca
                            procedencia: $procedencia
                            seguro: $seguro
                            valor: $valor
                            sucursal:$sucursal
                        ) {
                            success
                            error
                        }
                    }
                    `, variables: obj
    })
}

export const updateAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateVehiculo($id:Int!, $categoria:Int!, $color:String!,$fechaCirculacion:Date!, $fechaGps:Date!,
                 $fechaSeguro:Date!, $modelo:Int!,  $noEconomico:String!, $noSerie:String!,
                  $noPlaca:String!, $procedencia:String!, $seguro:String!, $valor:Decimal!, $sucursal:ID!) {
                    updateVehiculo(
                        id:$id
                        categoria: $categoria
                        color: $color
                        fechaCirculacion: $fechaCirculacion
                        fechaGps: $fechaGps
                        fechaSeguro: $fechaSeguro
                        modelo: $modelo
                        noEconomico: $noEconomico
                        noSerie:$noSerie
                        noPlaca:$noPlaca
                        procedencia: $procedencia
                        seguro: $seguro
                        valor: $valor
                        sucursal:$sucursal
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    })
}

export const searchContractByIdForDetails = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchContractById(id: "${id}"){
                        id
                        invoice{
                            xmlFile
                            pdfFile
                        }
                        invoiced
                        contratoNo
                        comentario
                        invoiced
                        fechaSalida
                        fechaRegreso
                        depositTarjetAuth
                        depositTarjetExp
                        depositTarjetNumber
                        dailyPrice
                        total
                        dias
                        totalServicios
                        totalProteccion
                        totalDropoff
                         promocion {
                            id
                            nombre
                            porciento
                            codigo
                        }
                        totalPromocion
                        iva
                        estado
                        comb
                        tarjetNumber
                        tarjetExp
                        metodoPago
                        deposit
                        depositCash
                        depositTransfer
                        depositTarjet
                        serviciosContratoSet{
                            edges{
                                node{
                                    servicio{
                                        id
                                        name
                                        diario
                                        precio
                                    }
                                }
                            }
                        }
                        cliente {
                            nombre
                            rfc
                            apellidos
                            tarjetaSet {
                                edges {
                                    node {
                                        numero
                                        vencimiento
                                    }
                                }
                            }
                            licenciaSet {
                                edges {
                                    node {
                                        numero
                                        vencimiento
                                    }
                                }
                            }
                            contactoSet {
                                edges {
                                    node {
                                        telefono
                                    }
                                }
                            }
                            direccionSet {
                                        edges {
                                            node {
                                                direccion
                                                postal
                                            }
                                        }
                                    }
                            user {
                                email
                            }
                        }
                        contratoSucursalRecogida {
                            ciudad{
                                iva
                            }
                            id
                            name
                        }
                        contratoSucursalEntrega {
                            name
                        }
                        proteccion {
                            id
                            tarifa
                            descripcion
                            name
                        }
                        procedencia {
                            nombre
                        }
                        vehiculo {
                            categoria {
                                name
                                tarifaAlta
                                tarifaBaja
                            }
                            noEconomico
                            color
                            kilometraje
                            noPlaca
                            sucursal {
                                name
                                ciudad {
                                    name
                                    estado {
                                        name
                                    }
                                }
                            }
                            modelo {
                                    id
                                    name
                                    marca {
                                        id
                                        name
                                    }
                                }
                        }
                    }
                }`
    });
}
export const searchContractsPromotions = (name) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchContractsPromotions($name:String!){
                    searchContractsPromotions (name:$name){
                        edges {
                            node {
                                id
                                createdBy{
                                email
                                }
                                contratoNo
                                invoiced
                                total
                                iva
                                totalPromocion
                                promocion{
                                codigo
                                }
                                fechaSalida
                                fechaRegreso
                                estado
                                cliente {
                                    id
                                    user {
                                        email
                                    }
                                }
                                procedencia {
                                    nombre
                                }
                                vehiculo {
                                    categoria {
                                        name
                                    }
                                }
                                invoice{
                                    xmlFile
                                    pdfFile
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`,
        variables: {name: name}
    });
}

export const isHightSeason = (date) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `
                query CleanReservations($date:DateTime!) {
                    isHighSeason(date: $date)
                }`, variables: {date: date}
    });
}

export const getBranchOfficeForContract = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SucursalesApp {
                    sucursalesApp {
                        id
                        name
                        isActive
                    }
                }`
    });
}

export const getAllClientsForContract = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ClientesByName {
                            clientesByName(name: "${name}") {
                                id
                                nombre
                                apellidos
                                isActive
                                rfc
                                user {
                                    email
                                }
                                direccionSet {
                                    edges {
                                        node {
                                            ciudad
                                            estado
                                            pais
                                            direccion
                                        }
                                    }
                                }
                                licenciaSet {
                                    edges {
                                        node {
                                            numero
                                            vencimiento
                                        }
                                    }
                                }
                                contactoSet {
                                    edges {
                                        node {
                                            telefono
                                            codigo
                                        }
                                    }
                                }
                                tarjetaSet {
                                    edges {
                                        node {
                                            vencimiento
                                            numero
                                        }
                                    }
                                }
                        }
                    }`
    });
}

export const getCategoriesForContract = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasByName {
                    categoriasByName(name: "${name}") {
                        id
                        name
                        tarifaAlta
                        tarifaBaja
                        isActive
                    }
                }`
    });
}

export const getProtectionsForContract = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionesApp {
                    proteccionesApp {
                        id
                        name
                        tarifa
                        isActive
                    }
                }`
    });
}

export const getServicesForContract = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosExtrasApp {
                    serviciosExtrasApp {
                        id
                        diario
                        visibleAll
                        name
                        precio
                        isActive
                    }
                }`
    });
}

export const getOriginsForContract = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProcedenciaByName {
                    procedenciaByName(name: "${name}") {
                        id
                        nombre
                        isActive
                    }
                }`
    });
}

export const getServicesContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosByContrato($id:ID!) {
                    serviciosByContrato(id: $id) {
                        id
                        servicio {
                            id
                            isActive
                            diario
                            name
                            precio
                        }
                    }
                }`, variables: {id: id}
    });
}

export const searchContractByIdForEdit = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchContractById(id: "${id}"){
                        id
                        contratoNo
                        comentario
                        fechaSalida
                        fechaRegreso
                        fecha
                        total
                        dailyPrice
                        totalServicios
                        totalProteccion
                        totalDropoff
                        depositCash
                        depositTransfer
                        depositTarjet
                        dias
                        comb
                        estado
                        metodoPago
                        transferConcept
                        transferReference
                        transferBank
                        deposit
                        depositTarjetNumber
                        depositTarjetExp
                        depositTarjetAuth
                        promocion {
                            id
                            nombre
                            porciento
                            codigo
                        }
                        depositTarjetNumber
                        depositTarjetExp
                        depositTarjetAuth
                        totalPromocion
                        tarjetNumber
                        tarjetExp
                        serviciosContratoSet{
                            edges{
                                node{
                                    servicio{
                                        id
                                    }
                                }
                            }
                        }
                        cliente {
                            id
                            nombre
                            apellidos
                            photo
                            direccionSet{
                                edges{
                                    node{
                                        estado
                                        ciudad
                                        pais
                                        direccion
                                    }
                                }
                            }
                            licenciaSet{
                                edges{
                                    node{
                                        numero
                                        vencimiento
                                    }
                                }
                            }
                            contactoSet {
                                edges {
                                    node {
                                        id
                                        telefono
                                        codigo
                                        idioma
                                    }
                                }
                            }
                            user {
                                email
                            }
                        }
                        contratoSucursalRecogida {
                            id
                            name
                        }
                        contratoSucursalEntrega {
                            id
                            name
                        }
                        proteccion {
                            id
                            name
                        }
                        procedencia {
                            id
                            nombre
                        }
                        vehiculo {
                            id
                            noEconomico
                            noPlaca
                            kilometraje
                            categoria {
                                id
                                name
                                tarifaAlta
                                tarifaBaja
                            }
                            modelo {
                                id
                                name
                                marca {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }`
    });
}

export const closeContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CloseContrato(
                    $contrato:ID!
                    $fecha:DateTime!
                    $horasExtras:Int!
                    $kilometraje:Decimal!
                    $litros:Decimal!
                    $metodoPago:String!
                    $montoPagar:Decimal!
                    $dias:Int!
                    $montoDamage:Decimal!
                    $reembolso_monto:Decimal!
                    $reembolso_dias:Int!
                    $depositCash:Decimal!
                    $depositTarjet: Decimal!
                    $depositTransfer: Decimal!
                    $transferReference: String!
                    $transferConcept: String!
                    $transferBank: String!
                    $tarjetNumber: String!
                    $tarjetExp: String!
                    $montoGasolina: Decimal!
                    $tarifaGasolina: Decimal!
                ) {
                    closeContrato(
                        contrato: $contrato
                        fecha: $fecha
                        horasExtras: $horasExtras
                        kilometraje: $kilometraje
                        litros: $litros
                        metodoPago: $metodoPago
                        montoPagar: $montoPagar
                        dias: $dias
                        montoDamage: $montoDamage
                        reembolsoMonto:$reembolso_monto
                        reembolsoDias:$reembolso_dias
                        depositCash:$depositCash
                        depositTarjet:$depositTarjet
                        depositTransfer:$depositTransfer
                        transferReference:$transferReference
                        transferConcept:$transferConcept
                        transferBank:$transferBank
                        tarjetNumber:$tarjetNumber
                        tarjetExp:$tarjetExp
                        montoGasolina:$montoGasolina
                        tarifaGasolina:$tarifaGasolina
                    ) {
                        success
                        error
                    }
                }
                `, variables: obj
    });
}

export const cancelReserva = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CancelReserva($id:ID!) {
                    cancelReserva(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: {id: id}
    });
}
export const cancelContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CancelContrato($id:ID!) {
                    cancelContrato(id: $id) {
                        success
                        error
                    }
                }`, variables: {id: id}
    });
}

export const dropOffAction = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query TarifaDropoff($sucursal1:ID!
                    $sucursal2:ID!) {
                        tarifaDropoff(sucursal1: $sucursal1, sucursal2: $sucursal2)
                    }`, variables: obj
    });
}

export const updateContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateContrato(
                    $id:ID!
                    $nombre:String!
                    $apellidos:String!
                    $telefono:String!
                    $codigoTelefono:String!
                    $correo:String!
                    $comentario:String!
                    $sucursalRecogida:ID!
                    $sucursalEntrega:ID!
                    $fechaSalida:DateTime!
                    $fechaRegreso:DateTime!
                    $proteccion:ID!
                    $metodoPago:String!
                    $procedencia:ID!
                    $total:Decimal!
                    $servicios:[ID]
                    $dias:Int!
                    $vehiculo:ID!
                    $transferConcept: String
                    $transferReference: String
                    $transferBank: String
                    $tarjetNumber: String
                    $tarjetExp: String
                    $gasolina: Decimal!
                    $dailyPrice:Decimal!
                    $iva:Decimal!
                    $deposit:Decimal!
                    $creator:ID!
                    $km:Int!
                    $licencia:String!
                    $vencimiento:DateTime!
                    $ciudad:String!
                    $state:String!
                    $pais:String!
                    $direccion:String!
                    $depositTarjetAuth:String!
                    $depositTarjetExp:String!
                    $depositTarjetNumber:String!
                    $depositTarjet:Decimal!
                    $depositCash:Decimal!
                    $depositTransfer:Decimal!                    
                ) {
                    updateContrato(
                        obj: {
                            id: $id
                            nombre: $nombre
                            comb: $gasolina
                            apellidos: $apellidos
                            telefono: $telefono
                            codigoTelefono: $codigoTelefono
                            correo: $correo
                            comentario: $comentario
                            sucursalRecogida: $sucursalRecogida
                            sucursalEntrega: $sucursalEntrega
                            fechaSalida: $fechaSalida
                            fechaRegreso: $fechaRegreso
                            proteccion: $proteccion
                            metodoPago: $metodoPago
                            procedencia: $procedencia
                            total: $total
                            servicios: $servicios
                            dias: $dias
                            vehiculo: $vehiculo
                            transferConcept: $transferConcept
                            transferReference: $transferReference
                            transferBank: $transferBank
                            tarjetNumber: $tarjetNumber
                            tarjetExp: $tarjetExp
                            dailyPrice:$dailyPrice
                            iva:$iva
                            creator:$creator
                            km:$km
                            deposit:$deposit
                            licencia:$licencia
                            vencimiento:$vencimiento
                            ciudad:$ciudad
                            state:$state
                            pais:$pais
                            direccion:$direccion
                            depositTarjetAuth:$depositTarjetAuth
                            depositTarjetExp:$depositTarjetExp
                            depositTarjetNumber:$depositTarjetNumber
                            depositTarjet:$depositTarjet
                            depositCash:$depositCash
                            depositTransfer:$depositTransfer
                        }
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const serviceByIdForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServicioById($id:ID!) {
                    servicioById(id: $id) {
                        id
                        isActive
                        diario
                        name
                        precio
                    }
                }`, variables: {id: id}
    });
}

export const getServiceFee = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query TarifaServicios($servicios:[ID!], $dias:Int!) {
                    tarifaServicios(servicios: $servicios, dias: $dias)
                }`, variables: obj
    });
}

export const createContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateContrato(
                    $nombre:String!
                    $apellidos:String!
                    $telefono:String!
                    $codigoTelefono:String!
                    $correo:String!
                    $comentario:String!
                    $sucursalRecogida:ID!
                    $sucursalEntrega:ID!
                    $fechaSalida:DateTime!
                    $fechaRegreso:DateTime!
                    $proteccion:ID!
                    $metodoPago:String!
                    $procedencia:ID!
                    $total:Decimal!
                    $servicios:[ID]
                    $dias:Int!
                    $vehiculo:ID!
                    $reserva:ID!
                    $transferConcept: String
                    $transferReference: String
                    $transferBank: String
                    $tarjetNumber: String
                    $tarjetExp: String
                    $gasolina: Decimal!
                    $dailyPrice:Decimal!
                    $iva:Decimal!
                    $deposit:Decimal!
                    $creator:ID!
                    $km:Int!
                    $extraDriver:[ID]
                    $licencia:String!
                    $vencimiento:DateTime!
                    $ciudad:String!
                    $state:String!
                    $pais:String!
                    $direccion:String!
                    $depositTarjetAuth:String!
                    $depositTarjetExp:String!
                    $depositTarjetNumber:String!
                    $depositTarjet:Decimal!
                    $depositCash:Decimal!
                    $depositTransfer:Decimal!
                ) {
                    createContrato(
                        obj: {
                            nombre: $nombre
                            comb: $gasolina
                            apellidos: $apellidos
                            telefono: $telefono
                            codigoTelefono: $codigoTelefono
                            correo: $correo
                            comentario: $comentario
                            sucursalRecogida: $sucursalRecogida
                            sucursalEntrega: $sucursalEntrega
                            fechaSalida: $fechaSalida
                            fechaRegreso: $fechaRegreso
                            proteccion: $proteccion
                            metodoPago: $metodoPago
                            procedencia: $procedencia
                            total: $total
                            servicios: $servicios
                            dias: $dias
                            vehiculo: $vehiculo
                            reserva:$reserva
                            transferConcept: $transferConcept
                            transferReference: $transferReference
                            transferBank: $transferBank
                            tarjetNumber: $tarjetNumber
                            tarjetExp: $tarjetExp
                            dailyPrice:$dailyPrice
                            iva:$iva
                            creator:$creator
                            km:$km
                            extraDriver:$extraDriver
                            deposit:$deposit
                            licencia:$licencia
                            vencimiento:$vencimiento
                            ciudad:$ciudad
                            state:$state
                            pais:$pais
                            direccion:$direccion
                            depositTarjetAuth:$depositTarjetAuth
                            depositTarjetExp:$depositTarjetExp
                            depositTarjetNumber:$depositTarjetNumber
                            depositTarjet:$depositTarjet
                            depositCash:$depositCash
                            depositTransfer:$depositTransfer
                        }
                    ) {
                        success
                        created
                        error
                    }
                }`, variables: obj
    });
}
export const createExtraDriver = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateExtraDriver(
            $nombre: String!
            $apellidos: String!
            $numero: String!
            $clase: String!
            $vencimiento: DateTime!
        ) {
        createExtraDriver(
            obj: {
                nombre: $nombre
                apellidos: $apellidos
                numero: $numero
                clase: $clase
                vencimiento: $vencimiento
            }
        ) {
            success
            driver
        }
    }`, variables: obj
    });
}

export const getAutoByIdForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query VehiculoById($id:ID!) {
                    vehiculoById(id: $id) {
                        id
                        noEconomico
                        noPlaca
                        modelo {
                            id
                            name
                        }
                        kilometraje
                    }
                }`, variables: {id: id}
    });
}

export const getProtectionByIdForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionById($id:ID!) {
                    proteccionById(id: $id) {
                        id
                        name
                        descripcion
                        tarifa
                        isActive
                    }
                }`, variables: {id: id}
    });
}

export const getAllMarcaForContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query MarcasByCategoria($category:ID!, $fechaSalida:DateTime!, $fechaRegreso:DateTime!, $ciudad:ID!) {
                    marcasByCategoria(categoria: $category, fechaSalida:$fechaSalida, fechaRegreso:$fechaRegreso, ciudad:$ciudad) {
                        id
                        name
                        isActive
                    }
                }`, variables: obj
    });
}

export const getAllAutoForContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query VehiculosByName($category:ID!, $fechaSalida:DateTime!, $fechaRegreso:DateTime!, $ciudad:ID!) {
                    vehiculosDisponibles(categoria: $category, fechaSalida:$fechaSalida, fechaRegreso:$fechaRegreso, ciudad:$ciudad) {
                        id
                        noEconomico
                        isActive
                        kilometraje
                        modelo{
                            id
                            marca{
                                id
                            }
                        }
                        categoria {
                            id
                            name
                        }
                        noPlaca
                    }
                }`, variables: obj
    });
}
export const getCarReserved = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query VehiculosByName($reserva:ID!) {
                    carReserved(reserva:$reserva) {
                        id
                        noEconomico
                        isActive
                        kilometraje
                        modelo{
                            id
                            marca{
                                name
                                id
                            }
                        }
                        categoria {
                            id
                            name
                        }
                        noPlaca
                    }
                }`, variables: obj
    });
}

export const getCategoryByIdForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriaById($id:ID!) {
                    categoriaById(id: $id) {
                        id
                        name
                        isActive
                        tarifaAlta
                        tarifaBaja
                        imagen
                        transmision
                        descripcion
                        puntos
                        image1
                        image2
                        image3
                        image4
                    }
                }`, variables: {id: id}
    });
}

export const getReserveForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReservaById($id:ID!) {
                    reservaById(id:$id) {
                        id
                        reservaNo
                        comentario
                        fechaSalida
                        fechaRegreso
                        fecha
                        dias
                        total
                        totalServicios
                        totalProteccion
                        totalDropoff
                        totalPromocion
                        promocion {
                            id
                            nombre
                            imagen
                            codigo
                            fechaInicio
                            fechaFin
                            descripcion
                            porciento
                        }
                        montoAPagar
                        estado
                        iva
                        priceExtra
                        isPriceExtra
                        montoAPagar
                        cliente {
                            id
                                nombre
                                apellidos
                                isActive
                                rfc
                                user {
                                    email
                                }
                                direccionSet {
                                    edges {
                                        node {
                                            ciudad
                                            estado
                                            pais
                                            direccion
                                        }
                                    }
                                }
                                licenciaSet {
                                    edges {
                                        node {
                                            numero
                                            vencimiento
                                        }
                                    }
                                }
                                contactoSet {
                                    edges {
                                        node {
                                            telefono
                                            codigo
                                        }
                                    }
                                }
                                tarjetaSet {
                                    edges {
                                        node {
                                            vencimiento
                                            numero
                                        }
                                    }
                                }
                        }
                        reservaSucursalRecogida {
                            id
                            name
                        }
                        serviciosReservaSet{
                            edges{
                                node{
                                    servicio{
                                        id
                                    }
                                }
                            }
                        }
                        reservaSucursalEntrega {
                            id
                            name
                        }
                        categoria {
                            id
                            name
                            tarifaAlta
                            tarifaBaja
                        }
                        proteccion {
                            id
                            name
                            descripcion
                            tarifa
                        }
                        metodoPago
                        procedencia {
                            id
                            nombre
                        }
                    }
                }`, variables: {id: id}
    });
}
export const getIvaForSuc = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query IvaBySucursal($id:ID!) {
                ivaBySucursal(sucursal: $id)}`,
        variables: {id: id}
    });
}
export const extraDriverByContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ExtraDriverByContract($id:ID!) {
                    extraDriverByContract(id: $id) {
                        id
                        extraDriver {
                            id
                            nombre
                            apellidos
                            numero
                            clase
                            vencimiento
                        }
                    }
                }`,
        variables: {id: id}
    });
}

export const getReserveServiceForContract = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosByReserva($id:ID!) {
                    serviciosByReserva(id: $id) {
                        id
                        servicio {
                            id
                            isActive
                            diario
                            name
                            precio
                        }
                    }
                }`, variables: {id: id}
    });
}

export const getProtectionsById = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionesById($id: ID!) {
                    proteccionesById(id: $id) {
                        id
                        nombre
                        isActive
                    }
                }`, variables: {id: id}
    });
}

export const getNotificationsNotRead = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchFirebaseNotifications(readadmin_Read: false, readadmin_Admin_Id: ${id}){
                        pageInfo{
                            hasNextPage
                        }
                        edges{
                            node{
                                id
                                title
                                message
                                createdAt
                            }
                        }
                    }
                }`
    });
}

export const getNotifications = (id, message = "", next = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchFirebaseNotifications(first: 10, after: "${next}", message_Icontains: "${message}"){
                        pageInfo{
                            hasNextPage
                            endCursor
                        }
                        edges{
                            node{
                                id
                                title
                                message
                                createdAt
                                readadminSet(admin_Id: ${id}){
                                    edges{
                                        node{
                                            id
                                            read
                                        }
                                    }
                                }
                                client{
                                    nombre
                                    apellidos
                                }
                            }
                        }
                    }
                }`
    });
}


export const readMessage = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($id: String!){
                    readAdmin(id: $id){
                        ok
                    }
                }`, variables: {id: id}
    });
}


export const searchNotifications = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchFirebaseNotifications(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                    $id: ID
                ) {
                    searchFirebaseNotifications(
                        before: $before
                        after: $after
                        first: $first
                        message_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                title
                                message
                                contract {
                                    id
                                    contratoNo                   
                                }
                                readadminSet(admin_Id: $id){
                                    edges{
                                        node{
                                            id
                                            read
                                        }
                                    }
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchPrevNotifications = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchFirebaseNotifications(
                    $name: String!
                    $before: String!
                    $first: Int!
                    $id: ID
                ) {
                    searchFirebaseNotifications(
                        before: $before
                        first: $first
                        message_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                title
                                message
                                readadminSet(admin_Id: $id){
                                    edges{
                                        node{
                                            id
                                            read
                                        }
                                    }
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchNextNotifications = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchFirebaseNotifications(
                    $name: String!
                    $after:String!
                    $first:Int!
                    $id: ID
                    ) {
                    searchFirebaseNotifications(
                        after: $after
                        first: $first
                        message_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                title
                                message
                                readadminSet(admin_Id: $id){
                                    edges{
                                        node{
                                            id
                                            read
                                        }
                                    }
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const disableUser = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactivarUsuario($id:Int!) {
                    desactivarUsuario(id: $id) {
                        success
                    }
                }`, variables: obj
    })
}

export const enableUser = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActivarUsuario($id:Int!) {
                    activarUsuario(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const updateUser = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EditarUsuario(
                    $email: String!,
                    $firstName: String!,
                    $id: Int!,
                    $lastName: String!,
                    $phone: String!,
                    $username: String!,
                ) {
                    editarUsuario(
                        email: $email
                        firstName: $firstName
                        id: $id
                        lastName: $lastName
                        phone: $phone
                        username: $username
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const createUser = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ($username: String!, $email: String!, $firstName: String!, $lastName: String!,
                   $password: String!, $password1: String!, $group: Int!, $phone: String!) {
                    nuevoUsuario(
                        firstName: $firstName
                        email: $email
                        lastName: $lastName
                        username: $username
                        password: $password
                        password1: $password1
                        groups: $group
                        phone: $phone
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const searchGroupsForUser = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchExtendGroups(isActive: true){
                        edges{
                            node{
                                id
                                name
                            }
                        }
                    }
                }`
    });
}

export const searchUsers = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchExtendUsers(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                ) {
                    searchExtendUsers(
                        before: $before
                        after: $after
                        first: $first
                        firstName_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                username
                                firstName
                                lastName
                                email
                                isActive
                                phone
                                isSuperuser
                                groups {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchPrevUsers = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchExtendUsers(
                    $name: String!
                    $before: String!
                    $first: Int!
                ) {
                    searchExtendUsers(
                        before: $before
                        first: $first
                        firstName_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                username
                                firstName
                                lastName
                                email
                                isActive
                                phone
                                isSuperuser
                                groups {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchNextUsers = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchExtendUsers(
                    $name: String!
                    $after:String!
                    $first:Int!
                    ) {
                    searchExtendUsers(
                        after: $after
                        first: $first
                        firstName_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                username
                                firstName
                                lastName
                                email
                                isActive
                                phone
                                isSuperuser
                                groups {
                                    id
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchCategories = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchCategories(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                ) {
                    searchCategories(
                        before: $before
                        after: $after
                        first: $first
                        name_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                name
                                isActive
                                tarifaAlta
                                tarifaBaja
                                imagen
                                transmision
                                descripcion
                                puntos
                                image1
                                image2
                                image3
                                image4
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    });
}

export const searchGroups = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchExtendGroups(name_Icontains: "${name}"){
                        pageInfo
                        {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                            edges{
                                node{
                                    id
                                    name
                                    isActive
                                }
                            }
                        }
                    }`
    });
}


export const createGroup = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation NuevoGrupo($name:String!) {
                    nuevoGrupo(name: $name) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const clientePhoto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ClientePhoto(                  
                    $photo:String!
                    $id:ID!
                ) {
                    clientePhoto(
                        photo: $photo
                        id: $id
                    ) {
                        error
                        success
                    }
                }`, variables: obj
    });
}
export const clienteIdentidad = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ClienteIdentidad(
                    $carnetBack:String!
                    $carnetFront:String!
                    $id:ID!) {
                    clienteIdentidad(carnetBack: $carnetBack, carnetFront: $carnetFront, id: $id) {
                        error
                        success
                    }
                }`, variables: obj
    });
}
export const clienteLicencia = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ClienteLicencia($id:ID!
                                        $licenciaBack:String!
                                        $licenciaFront:String!) {
                        clienteLicencia(id: $id, licenciaBack: $licenciaBack, licenciaFront: $licenciaFront) {
                            error
                            success
                        }
                    }`, variables: obj
    });
}


export const addDamage = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation AddDamage($id:ID!
                    $damage:String!
                    $price:Decimal!) {
                addDamage(id: $id, damage: $damage, price: $price) {
                    success
                    error
                }
            }`, variables: obj
    });
}

export const deleteDamage = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DeleteDamage($id:ID!) {
                    deleteDamage(id: $id) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const getDamagesByContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query GetDamagesByContract($id:ID!) {
                    getDamagesByContract(id: $id) {
                        id
                        damage
                        price
                    }
                }`, variables: obj
    });
}


export const createHigthSeason = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation SetHighSeason(
                    $firstDate:Date!
                    $lastDate:Date!
                    $name:String!
                    ) {
                    setHighSeason(firstDate: $firstDate, lastDate: $lastDate, name: $name) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const updateHigthSeason = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateHighSeason(
                    $firstDate:Date!
                    $lastDate:Date!
                    $name:String!
                    $id:ID!
                    ) {
                    updateHighSeason(firstDate: $firstDate, lastDate: $lastDate, name: $name, id:$id) {
                            success
                            error
                    }
            }`, variables: obj
    });
}

export const updateGroup = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EditarGrupo($id:Int!, $name:String!) {
                    editarGrupo(id: $id, name: $name) {
                        success
                    }
                }`, variables: obj
    });
}

export const searchPrevCategories = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchCategories(
                    $name: String!
                    $before: String!
                    $first: Int!
                ) {
                    searchCategories(
                        before: $before
                        first: $first
                        name_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                name
                                isActive
                                tarifaAlta
                                tarifaBaja
                                imagen
                                transmision
                                descripcion
                                puntos
                                image1
                                image2
                                image3
                                image4
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    });
}

export const searchNextCategories = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchCategories(
                    $name: String!
                    $after:String!
                    $first:Int!
                    ) {
                    searchCategories(
                        after: $after
                        first: $first
                        name_Icontains:$name
                    ) {
                        edges {
                            node {
                                id
                                name
                                isActive
                                tarifaAlta
                                tarifaBaja
                                imagen
                                transmision
                                descripcion
                                puntos
                                image1
                                image2
                                image3
                                image4
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    });
}

export const clientPoint = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdatePuntosClientes($cliente:ID!, $puntos:Int!) {
                    updatePuntosClientes(obj: { cliente: $cliente, puntos: $puntos }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableClient = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveCliente($id:ID!) {
                    activeCliente(clienteId: $id) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const disableClient = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveCliente($id:ID!) {
                    desactiveCliente(clienteId: $id) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const reserveById = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReservaById($id:ID!) {
                    reservaById(id:$id) {
                        id
                        reservaNo
                        comentario
                        fechaSalida
                        fechaRegreso
                        fecha
                        dias
                        puntos
                        total
                        totalServicios
                        totalProteccion
                        totalDropoff
                        montoAPagar
                        estado
                        iva
                        totalPromocion
                        promocion {
                            id
                            nombre
                            imagen
                            codigo
                            fechaInicio
                            fechaFin
                            descripcion
                            porciento
                        }
                        priceExtra
                        transferConcept
                        transferReference
                        transferBank
                        tarjetNumber
                        tarjetExp
                        isPriceExtra
                        montoAPagar
                        cliente {
                            id
                            nombre
                            apellidos
                            rfc
                            contactoSet {
                              edges {
                                node {
                                    telefono
                                    codigo
                                }
                              }
                            }
                            user {
                                id
                                email
                            }
                        }
                        reservaSucursalRecogida {
                            id
                            name
                        }
                        reservaSucursalEntrega {
                            id
                            name
                        }
                        categoria {
                            id
                            name
                            tarifaAlta
                            tarifaBaja
                        }
                        proteccion {
                            id
                            name
                            descripcion
                            tarifa
                        }
                        metodoPago
                        procedencia {
                            id
                            nombre
                        }
                        }
                        }`, variables: obj
    });
}

export const createReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateReservaAdmin
                (
                            $nombre: String!
                            $apellidos: String!
                            $telefono: String!
                            $codigoTelefono: String!
                            $correo: String!
                            $comentario: String!
                            $sucursalRecogida: ID!
                            $sucursalEntrega: ID!
                            $fechaSalida: DateTime!
                            $fechaRegreso: DateTime!
                            $categoria: ID!
                            $proteccion: ID!
                            $metodoPago: String!
                            $procedencia: ID!
                            $total: Decimal!
                            $servicios: [ID!]
                            $dias:Int!
                            $iva:Decimal!
                            $isPriceExtra: Boolean!
                            $priceExtra: Decimal,
                            $montoAPagar:Decimal!
                            $creator:ID!
                            $transferConcept: String
                            $transferReference: String
                            $transferBank: String
                            $tarjetNumber: String
                            $tarjetExp: String
                ) {
                    createReservaAdmin(
                        obj: {
                            nombre: $nombre
                            apellidos: $apellidos
                            telefono: $telefono
                            codigoTelefono: $codigoTelefono
                            correo: $correo
                            comentario: $comentario
                            sucursalRecogida: $sucursalRecogida
                            sucursalEntrega: $sucursalEntrega
                            fechaSalida: $fechaSalida
                            fechaRegreso: $fechaRegreso
                            categoria: $categoria
                            proteccion: $proteccion
                            metodoPago: $metodoPago
                            procedencia: $procedencia
                            total: $total
                            servicios: $servicios
                            dias:$dias
                            iva:$iva
                            montoAPagar:$montoAPagar
                            priceExtra: $priceExtra
                            isPriceExtra: $isPriceExtra
                            creator:$creator
                            transferConcept: $transferConcept
                            transferReference: $transferReference
                            transferBank: $transferBank
                            tarjetNumber: $tarjetNumber
                            tarjetExp: $tarjetExp
                        }
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateReservaAdmin
                (
                            $id:ID!
                            $nombre: String!
                            $apellidos: String!
                            $telefono: String!
                            $codigoTelefono: String!
                            $correo: String!
                            $comentario: String!
                            $sucursalRecogida: ID!
                            $sucursalEntrega: ID!
                            $fechaSalida: DateTime!
                            $fechaRegreso: DateTime!
                            $categoria: ID!
                            $proteccion: ID!
                            $metodoPago: String!
                            $procedencia: ID!
                            $total: Decimal!
                            $servicios: [ID!]
                            $dias:Int!
                            $priceExtra: Decimal
                            $isPriceExtra: Boolean!
                            $montoAPagar:Decimal!
                            $creator:ID!
                            $transferConcept: String
                            $transferReference: String
                            $transferBank: String
                            $tarjetNumber: String
                            $tarjetExp: String
                            $iva:Decimal!
                ) {
                    updateReservaAdmin(
                        obj: {
                            id:$id
                            nombre: $nombre
                            apellidos: $apellidos
                            telefono: $telefono
                            codigoTelefono: $codigoTelefono
                            correo: $correo
                            comentario: $comentario
                            sucursalRecogida: $sucursalRecogida
                            sucursalEntrega: $sucursalEntrega
                            fechaSalida: $fechaSalida
                            fechaRegreso: $fechaRegreso
                            categoria: $categoria
                            proteccion: $proteccion
                            metodoPago: $metodoPago
                            procedencia: $procedencia
                            total: $total
                            servicios: $servicios
                            dias:$dias
                            priceExtra: $priceExtra
                            isPriceExtra: $isPriceExtra
                            montoAPagar:$montoAPagar
                            creator:$creator
                            transferConcept: $transferConcept
                            transferReference: $transferReference
                            transferBank: $transferBank
                            tarjetNumber: $tarjetNumber
                            tarjetExp: $tarjetExp
                            iva:$iva
                        }
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const getIVA = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query IvaBySucursal($id:ID!) {
                    ivaBySucursal(sucursal: $id)
                }`, variables: obj
    });
}

export const getClientForReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ClientesByName {
                    clientesByName(name: "") {
                        id
                                nombre
                                apellidos
                                isActive
                                rfc
                                user {
                                    email
                                }
                                direccionSet {
                                    edges {
                                        node {
                                            ciudad
                                            estado
                                            pais
                                            direccion
                                        }
                                    }
                                }
                                licenciaSet {
                                    edges {
                                        node {
                                            numero
                                            vencimiento
                                        }
                                    }
                                }
                                contactoSet {
                                    edges {
                                        node {
                                            telefono
                                            codigo
                                        }
                                    }
                                }
                                tarjetaSet {
                                    edges {
                                        node {
                                            vencimiento
                                            numero
                                        }
                                    }
                                }
                    }
                }`
    });
}

export const createClient = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateCliente(
                    $apellidos:String!,
                    $direccion:String!,
                    $direccionCiudad:String!,
                    $direccionEstado:String!,
                    $direccionPais:String!,
                    $email:String!,
                    $fechaNacimiento:Date!,
                    $identidadExpedicion:Date!,
                    $identidadNumero:String!,
                    $identidadPais:String!,
                    $identidadTipo:String!,
                    $licenciaClase:String!,
                    $licenciaOtorgamiento:Date!,
                    $licenciaVencimiento:Date!,
                    $licenciaNumero:String!,
                    $nacionalidad:String!,
                    $nombre:String!,
                    $postal:String!,
                    $telefono:String!,
                    $tratamiento:String!,
                    $idioma:String!
                    $tarjeta_numero:String!,
                    $tarjeta_vencimiento:Date!,
                    $codigo:String!) {
                createCliente(
                    apellidos: $apellidos
                    direccion: $direccion
                    direccionCiudad: $direccionCiudad
                    direccionEstado: $direccionEstado
                    direccionPais: $direccionPais
                    email: $email
                    fechaNacimiento: $fechaNacimiento
                    identidadExpedicion: $identidadExpedicion
                    identidadNumero: $identidadNumero
                    identidadPais: $identidadPais
                    identidadTipo: $identidadTipo
                    licenciaClase: $licenciaClase
                    licenciaOtorgamiento: $licenciaOtorgamiento
                    licenciaVencimiento: $licenciaVencimiento
                    licenciaNumero: $licenciaNumero
                    nacionalidad: $nacionalidad
                    nombre: $nombre
                    postal: $postal
                    telefono: $telefono
                    tratamiento: $tratamiento
                    idioma: $idioma
                    tarjetaNumero: $tarjeta_numero
                    tarjetaVencimiento: $tarjeta_vencimiento
                    codigo:$codigo
                ) {
                    success
                    error
                }
            }`, variables: obj
    });
}

export const updateClient = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateCliente(
                    $cliente_id:ID!,
                    $apellidos:String!,
                    $direccion:String!,
                    $direccionCiudad:String!,
                    $direccionEstado:String!,
                    $direccionPais:String!,
                    $email:String!,
                    $fechaNacimiento:Date!,
                    $identidadExpedicion:Date!,
                    $identidadNumero:String!,
                    $identidadPais:String!,
                    $identidadTipo:String!,
                    $licenciaClase:String!,
                    $licenciaOtorgamiento:Date!,
                    $licenciaVencimiento:Date!,
                    $licenciaNumero:String!,
                    $nacionalidad:String!,
                    $nombre:String!,
                    $postal:String!,
                    $telefono:String!,
                    $tratamiento:String!,
                    $idioma:String!,
                    $tarjeta_numero:String!,
                    $tarjeta_vencimiento:Date!,
                    $codigo:String!) {
                updateCliente(
                    clienteId:$cliente_id
                    apellidos: $apellidos
                    direccion: $direccion
                    direccionCiudad: $direccionCiudad
                    direccionEstado: $direccionEstado
                    direccionPais: $direccionPais
                    email: $email
                    fechaNacimiento: $fechaNacimiento
                    identidadExpedicion: $identidadExpedicion
                    identidadNumero: $identidadNumero
                    identidadPais: $identidadPais
                    identidadTipo: $identidadTipo
                    licenciaClase: $licenciaClase
                    licenciaOtorgamiento: $licenciaOtorgamiento
                    licenciaVencimiento: $licenciaVencimiento
                    licenciaNumero: $licenciaNumero
                    nacionalidad: $nacionalidad
                    nombre: $nombre
                    postal: $postal
                    telefono: $telefono
                    tratamiento: $tratamiento
                    idioma: $idioma
                    tarjetaNumero: $tarjeta_numero
                    tarjetaVencimiento: $tarjeta_vencimiento
                    codigo:$codigo
                ) {
                    success
                    error
                }
            }`, variables: obj
    });
}

export const enableAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveVehiculo($id:Int!) {
                    activeVehiculo(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveVehiculo($id:Int!) {
                    desactiveVehiculo(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const enableOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveEmpresa($id:ID!) {
                    activeEmpresa(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const disableOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveEmpresa($id:ID!) {
                    desactiveEmpresa(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const getOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query EmpresaByName($name:String!) {
                    empresaByName(name: $name) {
                        id
                        nombre
                        direccion
                        codigoTelefono
                        telefono
                        correo
                        rcf
                        isActive
                    }
                }`, variables: obj
    });
}
export const enableComicionista = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation Activecomisionista($id:ID!) {
                    activeComisionista(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const disableComicionista = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation Desactivecomisionista($id:ID!) {
                    desactiveComisionista(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const getComicionista = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ComisionistaByName($name:String!) {
                    comisionistaByName(name: $name) {
                        id
                        nombre
                        identificacion
                        codigoTelefono
                        telefono
                        correo
                        isActive
                        tipoExterno
                        tipo
                        porciento
                        empresa {
                            id
                            nombre
                            direccion
                            codigoTelefono
                            telefono
                            correo
                            rcf
                            isActive
                        }
                    }
                }`, variables: obj
    });
}
export const enableDropOff = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveDropoff($id:ID!) {
                    activeDropoff(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const disableDropOff = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveDropoff($id:ID!) {
                    desactiveDropoff(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}
export const getDropOff = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query DropoffByName($name:String!) {
                    dropoffByName(name: $name) {
                        id
                        tarifa
                        isActive
                        dropoffSucursalRecogida {
                            id
                            name
                        }
                        dropoffSucursalEntrega {
                            id
                            name
                        }
                    }
                }`, variables: obj
    });
}
export const getAllContractClosed = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query GetAllContractClosed{
                    getAllContractClosed {
                        id
                        fecha
                        litros
                        horasExtras
                        dias
                        montoDamage
                        kilometraje
                        montoPagar
                        metodoPago
                        reembolsoMonto
                        reembolsoDias
                        depositCash
                        depositTransfer
                        transferConcept
                        transferReference
                        transferBank
                        depositTarjet
                        tarjetNumber
                        tarjetExp
                        montoGasolina
                        tarifaGasolina
                        contrato {
                            id
                            total
                            dias
                            dailyPrice
                            totalServicios
                            totalProteccion
                            totalDropoff
                            contratoSucursalRecogida{
                              ciudad{
                                iva
                              }
                            }    
                        }
                    }
                }`
    });
}


export const createCategory = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateCategoria($name:String!,$descripcion:String!, $tarifaAlta:Decimal!,
                    $tarifaBaja:Decimal!, $transmision:String!, $puntos:Boolean!) {
                        createCategoria(name: $name, puntos:$puntos
                        descripcion: $descripcion, tarifaAlta: $tarifaAlta,
                         tarifaBaja: $tarifaBaja, transmision: $transmision) {
                            success
                            error
                            id
                        }
                    }`, variables: obj
    });
}

export const updateCategory = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateCategoria($name:String!,$descripcion:String!, $tarifaAlta:Decimal!,
                    $tarifaBaja:Decimal!, $transmision:String!, $id:Int!, $puntos:Boolean!) {
                        updateCategoria(name: $name,
                        descripcion: $descripcion, tarifaAlta: $tarifaAlta, tarifaBaja: $tarifaBaja,
                        transmision: $transmision, id:$id, puntos:$puntos) {
                            success
                            error
                        }
                    }`, variables: obj
    });
}

export const enableCategory = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveCategoria($id:Int!) {
                    activeCategoria(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableCategory = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveCategoria($id:Int!) {
                    desactiveCategoria(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const uploadPhotos = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UploadAutoPhoto(
                    $id:ID!
                    $image1:String!
                    $image2:String!
                    $image3:String!
                    $image4:String!
                ) {
                    uploadAutoPhoto(id: $id, image1: $image1, image2: $image2, image3: $image3, image4: $image4) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const uploadPhotoCategory = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UploadCategoryPhoto($id:ID!, $photo: String!) {
                    uploadCategoryPhoto(id: $id, photo: $photo) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const createPermission = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation NuevoPermiso($description:String!, $name:String!) {
                    nuevoPermiso(description: $description, name: $name) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updatePermission = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EditarPermiso($description:String!, $name:String!, $id:ID!) {
                    editarPermiso(description: $description, id: $id, name: $name) {
                        success
                    }
                }`, variables: obj
    });
}

export const deletePermission = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EliminarPermiso($id:Int!) {
                    eliminarPermiso(id: $id) {
                        error
                        success
                    }
                }`, variables: obj
    });
}

export const searchPermissions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query PermisosByName($name:String!) {
                    permisosByName(name: $name) {
                        id
                        name
                        description
                    }
                }`, variables: obj
    });
}
export const searchPermissionsByGroup = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query PermisosByName($id:ID!) {
                    permisosByGroup(id: $id) {
                        id
                        name
                        description
                    }
                }`, variables: obj
    });
}
export const agregarPermiso = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation AgregarPermiso($groupId:ID!, $permissionId:ID!) {
                    agregarPermiso(groupId: $groupId, permissionId: $permissionId) {
                        success
                        errors
                    }
                }
                `, variables: obj
    });
}
export const quitarPermiso = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation AgregarPermiso($groupId: ID!, $permissionId: ID!) {
                    quitarPermiso(groupId: $groupId, permissionId: $permissionId) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const getBranchOfficeReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SucursalesApp {
                    sucursalesApp {
                        id
                        name
                        direccion
                        isActive
                    }
                }`
    });
}

export const getCategoriesReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasByName {
                    categoriasByName(name: "") {
                        id
                        name
                        isActive
                        tarifaAlta
                        tarifaBaja
                        imagen
                        transmision
                        descripcion
                    }
                }`
    });
}

export const getProtectionsReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionesApp {
                    proteccionesApp {
                        id
                        name
                        descripcion
                        tarifa
                        isActive
                    }
                }`
    });
}

export const getServicesReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosExtrasApp {
                    serviciosExtrasApp {
                        id
                        isActive
                        diario
                        name
                        precio
                    }
                }`
    });
}

export const getOriginReserve = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProcedenciaByName {
                    procedenciaByName(name: "") {
                        id
                        nombre
                        isActive
                    }
                }`
    });
}

export const getServicesSearchBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SucursalesByName($name:String!) {
                    sucursalesByName(name: $name) {
                        id
                        name
                        direccion
                        isActive
                        ciudad {
                            id
                            name
                            isActive
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                        }
                    }
                }`, variables: obj
    });
}

export const createBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateSucursal($ciudad:Int!, $direccion:String!, $name:String!) {
                    createSucursal(ciudad: $ciudad, direccion: $direccion, name: $name) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateSucursal ($ciudad:Int!, $direccion:String!, $name:String!, $id:Int!) {
                    updateSucursal(ciudad: $ciudad, direccion: $direccion, name: $name, id:$id) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveSucursal($id:Int!) {
                    activeSucursal(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveSucursal($id:Int!) {
                    desactiveSucursal(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const getStatesBranchOffice = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query EstadosByName {
                    estadosByName(name: "") {
                        id
                        name
                        abreviatura
                        isActive
                    }
                }`
    });
}

export const getCitiesBranchOffice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CiudadesByEstado($estado: Int!) {
                    ciudadesByEstado(estado: $estado) {
                        id
                        name
                        isActive
                        estado {
                            id
                            name
                            abreviatura
                            isActive
                        }
                    }
                }`, variables: obj
    });
}

export const createServices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateServiciosextras($name:String!, $nameEn:String!, $precio:Decimal!, $diario:Boolean!, $visible:Boolean!) {
                    createServiciosextras(name: $name, nameEn: $nameEn, diario:$diario, precio:$precio, visible:$visible) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateServices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateServiciosextras($id:Int!, $name:String!,  $nameEn:String!, $precio:Decimal!, $diario:Boolean!, $visible:Boolean!) {
                    updateServiciosextras(nameEn: $nameEn, id:$id, name: $name, diario:$diario, precio:$precio, visible:$visible) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableServices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveServiciosextras($id:Int!) {
                    activeServiciosextras(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableServices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveServiciosextras($id:Int!) {
                    desactiveServiciosextras(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const searchServices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosextrasByName($name:String!) {
                    serviciosextrasByName(name: $name) {
                        id
                        isActive
                        diario
                        name
                        nameEn
                        precio
                    }
                }`, variables: obj
    });
}

export const priceDropOffReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query TarifaDropoff($sucursal1:ID!
                    $sucursal2:ID!) {
                        tarifaDropoff(sucursal1: $sucursal1, sucursal2: $sucursal2)
                 }`, variables: obj
    });
}

export const priceServicesReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query TarifaServicios($servicios:[ID!], $dias:Int!) {
                    tarifaServicios(servicios: $servicios, dias: $dias)
                }`, variables: obj
    });
}

export const servicesReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ServiciosByReserva($id:ID!) {
                    serviciosByReserva(id: $id) {
                        id
                        servicio {
                            id
                            isActive
                            diario
                            name
                            precio
                        }
                    }
                }`, variables: obj
    });
}

export const categoryByIdReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriaById($id:ID!) {
                    categoriaById(id: $id) {
                        id
                        name
                        isActive
                        tarifaAlta
                        tarifaBaja
                        imagen
                        transmision
                        descripcion
                        puntos
                        image1
                        image2
                        image3
                        image4
                    }
                }`, variables: obj
    });
}

export const protectionByIdReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionById($id:ID!) {
                    proteccionById(id: $id) {
                        id
                        name
                        descripcion
                        tarifa
                        isActive
                    }
                }`, variables: obj
    });
}

export const protectionsByIdReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionesById($id:ID!) {
                    proteccionesById(id: $id) {
                        id
                        nombre
                        isActive
                    }
                }`, variables: obj
    });
}

export const availableCategoryReserve = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasDisponibles($fechaSalida:DateTime!
                    $fechaRegreso:DateTime!) {
                        categoriasDisponibles(fechaSalida: $fechaSalida, fechaRegreso: $fechaRegreso) {
                            id
                            name
                            isActive
                            tarifaAlta
                            tarifaBaja
                            imagen
                            transmision
                            descripcion
                            puntos
                        }
               }`, variables: obj
    });
}

export const searchProtections = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionByName($name:String) {
                    proteccionByName(name: $name) {
                        id
                        name
                        nameEn
                        descripcion
                        descripcionEn
                        tarifa
                        isActive
                    }
                }`, variables: obj
    });
}

export const createProtection = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateProteccion($name:String!, $descripcion:String!, $descripcionEn:String!, $tarifa:Decimal!, $nameEn:String!) {
                    createProteccion(obj: { name: $name, descripcion: $descripcion, descripcionEn: $descripcionEn, tarifa: $tarifa, nameEn:$nameEn }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateProtection = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateProteccion($name:String!, $descripcion:String!, $descripcionEn:String!, $tarifa:Decimal!, $id:ID!, $nameEn:String!) {
                    updateProteccion(obj: { name: $name, descripcion: $descripcion, descripcionEn: $descripcionEn, tarifa: $tarifa, id:$id, nameEn:$nameEn }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableProtection = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveProteccion($id:ID!) {
                    activeProteccion(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const disableProtection = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveProteccion($id:ID!) {
                    desactiveProteccion(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const protectionsByIdAction = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProteccionesById($id:ID!) {
                    proteccionesById(id: $id) {
                        id
                        nombre
                        nombreEn
                        isActive
                    }
                }`, variables: obj
    });
}

export const addProtection = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateProtecciones($nombre:String!, $nameEn:String!
                    $proteccion:ID!) {
                        createProtecciones(obj: { nombre: $nombre, proteccion: $proteccion, nombreEn:$nameEn }) {
                            success
                            error
                        }
                    }`, variables: obj
    });
}

export const updateProtectionAction = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateProtecciones($nombre:String!, $nameEn:String!
                    $proteccion:ID!, $id:ID!) {
                        updateProtecciones(obj: { nombre: $nombre, proteccion: $proteccion, id:$id, nombreEn:$nameEn }) {
                            success
                            error
                        }
                    }`, variables: obj
    });
}

export const activeProtectionAction = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveProtecciones($id:ID!) {
                    activeProtecciones(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const desactiveProtectionAction = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveProtecciones($id:ID!) {
                    desactiveProtecciones(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const searchPromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query PromocionesByName($name:String!) {
                    promocionesByName(name: $name) {
                        id
                        nombre
                        imagen
                        count
                        codigo
                        fechaInicio
                        fechaFin
                        descripcion
                        porciento
                        isActive
                    }
                }`, variables: obj
    });
}

export const createPromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreatePromocion(
                    $nombre:String!
                    $codigo:String!
                    $count:Int!
                    $fechaInicio:Date!
                    $fechaFin:Date!
                    $descripcion:String!
                    $porciento:Decimal!
                ) {
                    createPromocion(
                        obj: {
                            nombre: $nombre
                            codigo: $codigo
                            fechaInicio: $fechaInicio
                            fechaFin: $fechaFin
                            count:$count
                            descripcion: $descripcion
                            porciento: $porciento
                        }
                    ) {
                        success
                        error
                        info{
                            id
                            nombre
                            descripcion
                        }
                    }
                }`, variables: obj
    });
}

export const updatePromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdatePromocion(
                    $nombre:String!
                    $codigo:String!
                    $fechaInicio:Date!
                    $fechaFin:Date!
                    $count:Int!
                    $descripcion:String!
                    $porciento:Decimal!
                    $id:ID!
                ) {
                    updatePromocion(
                        obj: {
                            id: $id
                            nombre: $nombre
                            codigo: $codigo
                            fechaInicio: $fechaInicio
                            fechaFin: $fechaFin
                            count:$count
                            descripcion: $descripcion
                            porciento: $porciento
                        }
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enablePromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActivePromocion($id:ID!) {
                    activePromocion(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const disablePromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactivePromocion($id:ID!) {
                    desactivePromocion(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const uploadPhotoPromotions = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UploadPromocionPhoto($id:ID!, $file:String!) {
                    uploadPromocionPhoto(file: $file, id: $id) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const searchOrigins = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProcedenciaByName($name:String!) {
                    procedenciaByName(name: $name) {
                        id
                        nombre
                        isActive
                    }
                }`, variables: obj
    });
}

export const createOrigins = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateProcedencia($name: String!) {
                    createProcedencia(obj: { name: $name }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateOrigin = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateProcedencia($name:String!, $id:ID!) {
                    updateProcedencia(obj: { id: $id, name: $name }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableOrigin = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveProcedencia($id:ID!) {
                    activeProcedencia(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const disableOrigin = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveProcedencia($id:ID!) {
                    desactiveProcedencia(obj: { id: $id }) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const searchCountries = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query PaisesByName($name:String!) {
                    paisesByName(name: $name) {
                        id
                        name
                        abreviatura
                        isActive
                    }
                }`, variables: obj
    });
}

export const createCountry = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreatePais($name:String!, $abreviatura:String!) {
                    createPais(name: $name, abreviatura: $abreviatura) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateCountry = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdatePais($id: Int!, $name: String!, $abreviatura:String!) {
                    updatePais(id: $id, name: $name, abreviatura: $abreviatura) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableCountry = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActivePais($id:Int!) {
                    activePais(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableCountry = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactivePais($id:Int!) {
                    desactivePais(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const searchModel = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: ` query ModelosByName($name:String!) {
                    modelosByName(name: $name) {
                        id
                        name
                        isActive
                        marca {
                            id
                            name
                            isActive
                        }
                    }
                }`, variables: obj
    });
}

export const createModel = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateModelo($name:String!, $marca:Int!) {
                    createModelo(name: $name, marca: $marca) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const updateModel = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateCategoria($id: Int!, $name: String!, $marca:Int!) {
                    updateModelo(id: $id, name: $name, marca:$marca) {
                        success
                        error
                    }
                }`, variables: obj
    });
}

export const enableModel = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveCategoria($id:Int!) {
                    activeModelo(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const disableModel = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveCategoria($id:Int!) {
                    desactiveModelo(id: $id) {
                        success
                    }
                }`, variables: obj
    });
}

export const searchConfigs = (name = "") => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchConfigsProdigia(name_Icontains:"${name}"){
                        edges{
                            node{
                                id
                                isActive
                                isTest
                                name
                                noCert
                                rfc
                                url
                                cert
                                contractNo
                                emailSend
                                emailUser
                            }
                        }
                    }
                }`
    })
}

export const createConfig = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: CreateConfigProdigia){
                    createConfigProdigia(obj: $obj){
                        error
                        info{
                            id
                            isActive
                            isTest
                            name
                            noCert
                            rfc
                            url
                            cert
                            contractNo
                        }
                    }
                }`,
        variables: {
            obj: obj
        }
    })
}

export const updateConfig = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: UpdateConfigProdigia){
                    updateConfigProdigia(obj: $obj){
                        error
                        info{
                            id
                        }
                    }
                }`,
        variables: {
            obj: obj
        }
    })
}

export const changeStateConfig = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($id: String!){
                    changeStateConfigProdigia(id: $id){
                        info{
                            id
                        }
                        error
                    }
                }`,
        variables: {
            id: id
        }
    })
}

export const deleteConfig = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DeleteConfigProdigia($id: String!){
                    deleteConfigProdigia(id: $id){
                        success
                        error
                    }
                }`,
        variables: {
            id: id
        }
    })
}

export const getIssuersConfig = (id, name) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchIssuers(config_Id: ${id}, name_Icontains: "${name}"){
                        edges{
                            node{
                                id
                                name
                                rfc
                                taxRegime
                                postal
                            }
                        }
                    }
                }`
    });
}

export const createIssuer = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: CreateIssuer){
                    createIssuer(obj: $obj){
                        error
                        info{
                            id
                            name
                            rfc
                            taxRegime
                        }
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const updateIssuer = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: UpdateIssuer){
                    updateIssuer(obj: $obj){
                        error
                        info{
                            id
                            name
                            rfc
                            taxRegime
                        }
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const deleteIssuer = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($id: String!){
                    deleteIssuer(id: $id){
                        success
                        error
                    }
                }`,
        variables: {
            id: id
        }
    });
}

export const createInvoice = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: CreateInvoice){
                    createInvoice(obj: $obj){
                        info{
                            xmlFile
                            pdfFile
                        }
                        error
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const searchIssuersConfigActive = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchIssuers(config_IsActive: true){
                        edges{
                            node{
                                id
                                name
                                rfc
                            }
                        }
                    }
                }`
    });
}

export const isConfigActive = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    activeConfig
                }`
    });
}

export const getDataAutocompleteClient = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchContractById(id: "${id}"){
                        cliente{
                            nombre
                            apellidos
                            rfc
                            colonia
                            socialReason
                            municipality
                            stateInvoice
                            fiscalAddress
                            postalCodeInvoice
                            person
                            emailInvoice
                            fiscalRegimen
                        }
                    }
                }`
    });
}

export const createState = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateEstado($name:String!, $abreviatura:String!, $pais:Int!) {
                    createEstado(name: $name, abreviatura: $abreviatura, pais:$pais) {
                        success
                        error
                    }
                }`,
        variables: obj
    });
}

export const updateState = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateCategoria($id: Int!, $name: String!, $abreviatura:String!, $pais:Int!) {
                    updateEstado(id: $id, name: $name, abreviatura: $abreviatura, pais:$pais) {
                        success
                        error
                    }
                }`,
        variables: obj
    });
}

export const enableState = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveCategoria($id:Int!) {
                    activeEstado(id: $id) {
                        success
                    }
                }`,
        variables: obj
    });
}

export const disableState = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveCategoria($id:Int!) {
                    desactiveEstado(id: $id) {
                        success
                    }
                }`,
        variables: obj
    });
}

export const createCity = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateCiudad($name:String!, $estado:Int!, $siglas:String!, $iva:Decimal!) {
                    createCiudad(name: $name, estado: $estado, siglas:$siglas, iva:$iva) {
                        success
                        error
                    }
                }`,
        variables: obj
    });
}

export const updateCity = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UpdateCiudad($id: Int!, $name: String!, $estado:Int!, $siglas:String!, $iva:Decimal!) {
                    updateCiudad(id: $id, name: $name, estado:$estado, siglas:$siglas, iva:$iva) {
                        success
                        error
                    }
                }`,
        variables: obj
    });
}

export const enableCity = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ActiveCiudad($id:Int!) {
                    activeCiudad(id: $id) {
                        success
                    }
                }`,
        variables: obj
    });
}

export const disableCity = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DesactiveCiudad($id:Int!) {
                    desactiveCiudad(id: $id) {
                        success
                    }
                }`,
        variables: obj
    });
}

export const clientConfigInvoices = (contract) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    clientConfigInvoices(contract: "${contract}")
                }`
    });
}

export const fiscalInformationClient = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation ($obj: FiscalInformation){
                    fiscalInformationClient(obj: $obj){
                        success
                        error
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const getInfoInvoiceClient = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchClientById(id: "${id}"){
                        nombre
                        apellidos
                        municipality
                        socialReason
                        stateInvoice
                        fiscalAddress
                        colonia
                        rfc
                        emailInvoice
                        person
                        postalCodeInvoice
                        fiscalRegimen
                    }
                }`
    });
}

export const changePasswordConfigProdigia = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: ChangePasswordConfigProdigia){
                    changePasswordConfigProdigia(obj: $obj){
                        error
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const logoutUser = (token) => {
    return axios.post(`${process.env.VUE_APP_URL_SERVICES}auth/logout/`, {
        token: token
    });
}

export const searchInvoices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchInvoices(
                    $name: String!
                    $before: String!
                    $after: String!
                    $first: Int!
                ) {
                    searchInvoices(
                        client_Icontains: $name
                        before: $before
                        after: $after
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                client
                                rfc
                                contrato{
                                    contratoNo
                                }
                                xmlFile
                                pdfFile
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchPrevInvoices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchInvoices(
                    $name: String!
                    $before: String!
                    $first: Int!
                ) {
                    searchInvoices(
                        client_Icontains: $name
                        before: $before
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                client
                                rfc
                                contrato{
                                    contratoNo
                                }
                                xmlFile
                                pdfFile
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const searchNextInvoices = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SearchInvoices(
                    $name: String!
                    $after:String!
                    $first:Int!
                    ) {
                    searchInvoices(
                        client_Icontains: $name
                        after: $after
                        first: $first
                    ) {
                        edges {
                            node {
                                id
                                client
                                rfc
                                contrato{
                                    contratoNo
                                }
                                xmlFile
                                pdfFile
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`, variables: obj
    })
}

export const getBalance = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchBalance
                }`
    });
}

export const getReportPaymentMethods = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportPaymentMethods($weeks: Int, $month: Int, $year: Int, $dateInit: Date, $dateFinal: Date, 
                                           $branchOffice: String) {
                    reportPaymentMethods(
                        weeks: $weeks
                        month: $month
                        year: $year
                        dateInit: $dateInit
                        dateFinal: $dateFinal
                        branchOffice: $branchOffice
                    ) {
                        paymentMethod
                        money
                    }
                }`,
        variables: obj
    });
}

export const getReportSellAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportSellByAuto($month: Int, $year: Int, $dateInit: Date, $dateFinal: Date,
                                       $weeks: Int, $branchOffice: String, $pagination: PaginationInput!) {
                    reportSellByAuto(
                        weeks: $weeks
                        month: $month
                        year: $year
                        dateInit: $dateInit
                        dateFinal: $dateFinal
                        branchOffice: $branchOffice
                        pagination: $pagination
                    ) {
                        pagination {
                            page
                            pages
                        }
                        items {
                            roi
                            gan
                            contracts
                            auto {
                                noEconomico
                                noSerie
                                color
                                noPlaca
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                                categoria {
                                    name
                                }
                            }
                        }
                    }
                }`,
        variables: obj
    });
}

export const getServicesAuto = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportUnitsServices($weeks: Int, $month: Int, $year: Int, $dateInit: Date, $dateFinal: Date, 
                                          $branchOffice: String, $pagination: PaginationInput!) {
                    reportUnitsServices(
                        weeks: $weeks
                        month: $month
                        year: $year
                        dateInit: $dateInit
                        dateFinal: $dateFinal
                        branchOffice: $branchOffice
                        pagination: $pagination
                    ) {
                            pagination {
                                page
                                pages
                            }
                            items {
                                noEconomico
                                noSerie
                                color
                                noPlaca
                                estado
                                sucursal {
                                    name
                                    ciudad {
                                        name
                                    }
                                }
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                                categoria {
                                    name
                                }
                            }
                    }
                }`,
        variables: obj
    });
}

export const getReserveReport = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportReserve($typeReport: String, $originReserve: Int,, $branchOffice: String, $date: Date,
                                    $pagination: PaginationInput!) {
                    reportReserve(typeReport: $typeReport, originReserve: $originReserve, branchOffice: $branchOffice,
                                  date: $date, pagination: $pagination
                    ) {
                        pagination {
                            page
                            pages
                        }
                        items {
                            categoria {
                                name
                            }
                            estado
                            pending
                            reservaNo
                            cliente {
                                nombre
                                apellidos
                                user {
                                    email
                                }
                            }
                            fechaSalida
                            fechaRegreso
                            total
                            montoAPagar
                        }
                    }
                }`,
        variables: obj
    });
}

export const getOriginsForReserveReport = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ProcedenciaByName {
                    procedenciaByName(name: "") {
                        id
                        nombre
                    }
                }`
    });
}

export const getContractsReport = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportContracts($weeks: Int, $month: Int, $year: Int, $dateInit: Date, $date: Date, $dateFinal: Date,
                                      $state: String, $origin: Int, $branchOffice: String, 
                                      $pagination: PaginationInput!) {
                    reportContracts(
                        state: $state
                        origin: $origin
                        weeks: $weeks
                        month: $month
                        year: $year
                        date: $date
                        dateInit: $dateInit
                        dateFinal: $dateFinal
                        branchOffice: $branchOffice,
                        pagination: $pagination
                    ) {
                        pagination {
                            page
                            pages
                        }
                        items {
                            contratoNo
                            cliente {
                                nombre
                                apellidos
                                user {
                                    email
                                }
                            }
                            vehiculo {
                                categoria {
                                    name
                                }
                            }
                            contratoSucursalRecogida {
                                ciudad{
                                    iva
                                }
                                id
                                name
                            }
                            fecha
                            fechaSalida
                            fechaRegreso
                            procedencia {
                                nombre
                            }
                            total
                            iva
                            estado
                        }
                    }
                }`,
        variables: obj
    });
}
export const getReportContractsWithDamage = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ReportContractsWithDamage($weeks: Int, $month: Int, $year: Int, $dateInit: Date, $date: Date, $dateFinal: Date,
                                      $state: String, $noEcon: String, $branchOffice: String, 
                                      $pagination: PaginationInput!) {
                    reportContractsWithDamage(
                        state: $state
                        noEcon: $noEcon
                        weeks: $weeks
                        month: $month
                        year: $year
                        date: $date
                        dateInit: $dateInit
                        dateFinal: $dateFinal
                        branchOffice: $branchOffice,
                        pagination: $pagination
                    ) {
                        pagination {
                            page
                            pages
                        }
                        items {
                            contratoNo
                            damagescontractSet {
                                damage
                                price
                            }
                            cliente {
                                nombre
                                apellidos
                                user {
                                    email
                                }
                            }
                            vehiculo {
                                categoria {
                                    name
                                }
                                noEconomico
                                noSerie
                                noPlaca
                                color
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                            }
                            contratoSucursalRecogida {
                                ciudad{
                                    iva
                                }
                                id
                                name
                            }
                            contratoSucursalEntrega {                               
                                id
                                name
                            }
                            fecha
                            fechaSalida
                            fechaRegreso
                            procedencia {
                                nombre
                            }
                            total
                            iva
                            estado
                        }
                    }
                }`,
        variables: obj
    });
}

export const getBranchOffices = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchBranchoffices(isActive: true){
                        edges{
                            node {
                                id
                                name
                            }
                        }
                    }
                }`
    })
}


export const categoriasByDinero = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasByDinero {
                    categoriasByDinero {
                    mount
                    category {
                        id
                        name
                        imagen                    
                        }
                    }
                }`
    });
}

export const categoriasByDays = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasByDays {
                    categoriasByDays {
                        id
                        name
                        imagen
                        rentDays
                    }
                }`
    });
}

export const sucursalesByMoney = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query SucursalesByMoney($filter:String!) {
                    sucursalesByMoney(filter:$filter) {
                        id
                        name
                    }
                }`,
        variables: obj
    });
}
export const moneyBySucursal = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query MoneyBySucursal($id:ID!, $filter:String!) {
                    moneyBySucursal(id: $id, filter:$filter)
                }`,
        variables: obj
    });
}

export const vendedorByMoney = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query VendedorByMoney {
                    vendedorByMoney {
                        id
                        email
                        sucursal {
                            id
                            name
                        }
                    }
                }`
    });
}
export const moneyByVendedor = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query MoneyByVendedor($id:ID!) {
                    moneyByVendedor(id: $id)
                }`,
        variables: obj
    });
}

export const getMountByAutos = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query VehiculosByMonto {
                    vehiculosByMonto {
                        id
                        noEconomico
                        noSerie
                        monto
                        valor
                        modelo {
                            id
                            name
                            isActive
                            marca {
                                id
                                name
                                isActive
                            }
                        }
                    }
                }`
    });
}

export const searchCarReport = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchAutos(
                        name: ""
                        estado_Icontains: "${obj.state}"
                        ${obj.branchOffice ? "sucursal_Id: ".concat(decodeBase64(obj.branchOffice).toString()) : ""}
                        before: "${obj.before}"
                        after: "${obj.after}"
                        first: ${obj.first}
                    ) {
                        edges {
                            node {
                                noEconomico
                                estado
                                noPlaca
                                estado
                                isActive
                                sucursal {
                                    name
                                    ciudad {
                                        name
                                    }
                                }
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                                categoria {
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`
    })
}

export const searchNextCarReport = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchAutos(
                        name: ""
                        estado_Icontains: "${obj.state}"
                        ${obj.branchOffice ? "sucursal_Id: ".concat(decodeBase64(obj.branchOffice).toString()) : ""}
                        after: "${obj.after}"
                        first: ${obj.first}
                    ) {
                        edges {
                            node {
                                noEconomico
                                estado
                                isActive
                                noPlaca
                                estado
                                sucursal {
                                    name
                                    ciudad {
                                        name
                                    }
                                }
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                                categoria {
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`
    })
}

export const searchPrevCarReport = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    searchAutos(
                        name: ""
                        estado_Icontains: "${obj.state}"
                        ${obj.branchOffice ? "sucursal_Id: ".concat(decodeBase64(obj.branchOffice).toString()) : ""}
                        before: "${obj.before}"
                        first: ${obj.first}
                    ) {
                        edges {
                            node {
                                noEconomico
                                estado
                                noPlaca
                                isActive
                                estado
                                sucursal {
                                    name
                                    ciudad {
                                        name
                                    }
                                }
                                modelo {
                                    name
                                    marca {
                                        name
                                    }
                                }
                                categoria {
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }`
    })
}

export const getUserCurrent = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `{
                    getUserCurrent{
                        firstName
                        lastName
                        email
                        username
                        phone
                        code
                    }
                }`
    });
}


export const getContractFilesAdmin = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query GetContractFilesAdmin($id:ID!) {
                    getContractFilesAdmin(id: $id) {
                        id
                        photo
                        estado
                    }
                }`,
        variables: obj
    });
}

export const saveChangesProfile = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: UpdateProfileSerializer){
                    editProfile(obj: $obj){
                        info {
                            firstName
                            lastName
                            email
                            username
                            phone
                            code
                        }
                        error
                    }
        }`,
        variables: {
            obj: obj
        }
    });
}

export const changePasswordProfile = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($password: String!){
                    changePasswordProfile(password: $password){
                        ok
                        error
                    }
        }`,
        variables: obj
    });
}

export const guardarReservaPdf = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation GuardarReservaPdf($pdf:String!, $reserva:ID!) {
                    guardarReservaPdf(pdf: $pdf, reserva: $reserva) {
                        success
                        error
                    }
                }`,
        variables: obj
    })
}

export const savePdfContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation SavePdfContract($pdf:String!, $contrato:ID!) {
                    savePdfContract(pdf: $pdf, contrato: $contrato) {
                        success
                        error
                    }
                }`,
        variables: obj
    })
}

export const getAllClientsForNotifications = () => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query ClientesByName {
                    clientesByName(name: "") {
                        id
                        nombre
                        apellidos
                    }
                }`
    });
}

export const createCustomNotification = (obj) => {
    let data = new FormData();
    data.append('operations', `{
        "query":"mutation SendNotificationCustom($allClients: Boolean, $title: String!, $clients: [Int], $message: String!, $file: Upload) {sendNotificationCustom(obj: {allClients: $allClients, title: $title, image: $file ,message: $message, clients: $clients}) {ok error}}",
        "variables":{
            "file":null, 
            "allClients": ${obj.allClients}, 
            "title": "${obj.title}", 
            "message": "${obj.message}", 
            "clients": [${obj.clients.toString()}]
        },
        "operationName":null
    }`);
    data.append('map', '{"0":["variables.file"]}');
    data.append('0', obj.image, obj.image.name);
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, data, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": 'Bearer ' + sessionStorage.getItem('token')
        }
    });
};

export const createCustomNotificationPromotion = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation($obj: NotificationCustomSerializer){
                    sendNotificationCustom(obj: $obj){
                        ok
                        error
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const editNotification = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EditNotificationCustom($obj: EditNotificationCustomSerializer) {
                    editNotificationCustom(obj: $obj) {
                        error
                    }
                }`,
        variables: {
            obj: obj
        }
    });
}

export const deleteNotification = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DeleteNotification($id: String!) {
                    deleteNotification(id: $id) {
                        error
                    }
                }`,
        variables: obj
    });
}

export const uploadPhotoContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation UploadPhotoContract($contract:ID!, $photo:String!) {
                    uploadPhotoContract(contract: $contract, photo: $photo) {
                        success
                        error
                    }
                }`,
        variables: obj
    })
}

export const deletePhotoContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DeletePhotoContract($id:ID!) {
                    deletePhotoContract(id: $id) {
                        success
                        error
                    }
                }`,
        variables: obj
    })
}

export const createExtraDriverContract = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateExtraDriverContract(
                    $id:ID!
                    $nombre:String!
                    $apellidos:String!
                    $numero:String!
                    $clase:String!
                    $vencimiento:DateTime!
                ) {
                    createExtraDriverContract(
                        obj: {
                            id: $id
                            nombre: $nombre
                            apellidos: $apellidos
                            numero: $numero
                            clase: $clase
                            vencimiento: $vencimiento
                        }
                    ) {
                        success
                        driver
                    }
                }`, variables: obj
    })
}
export const deleteExtraDriver = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation DeleteExtraDriver($id:ID!) {
                    deleteExtraDriver(id: $id) {
                        success
                        error
                    }
                }`, variables: {id: id}
    })
}
export const getInfoContractClosed = (id) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query GetInfoContractClosed($id:ID!) {
                    getInfoContractClosed(id: $id) {
                        id
                        fecha
                        litros
                        horasExtras
                        dias
                        montoDamage
                        kilometraje
                        montoPagar
                        metodoPago
                        reembolsoMonto
                        reembolsoDias
                        depositCash
                        depositTransfer
                        transferConcept
                        transferReference
                        transferBank
                        depositTarjet
                        tarjetNumber
                        tarjetExp
                        montoGasolina
                        tarifaGasolina
                    }
                }`, variables: {id: id}
    })
}
export const getcategoriasDisponibles = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query CategoriasDisponibles($fechaSalida:DateTime!
                    $fechaRegreso:DateTime!, $sucursal:ID!) {
                        categoriasDisponibles(fechaSalida: $fechaSalida, fechaRegreso: $fechaRegreso, ciudad: $sucursal) {
                            id
                            name
                            isActive
                            tarifaAlta
                            tarifaBaja
                            imagen
                            transmision
                            descripcion
                            puntos
                        }
                    }`, variables: obj
    })
}

export const editKmInfo = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation EditKmInfo(
                    $id:ID!
                    $kilometraje:Decimal!
                    $kmControl:Decimal!
                    $kmService:Decimal!
                ) {
                    editKmInfo(
                        id: $id
                        kilometraje: $kilometraje
                        kmControl: $kmControl
                        kmService: $kmService
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    })
}
export const login = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation Login($email:String! $password:String!) {
                    login(email: $email password: $password) {
                        success
                        token
                        refreshToken
                        error
                        info {
                            id
                            username
                            firstName
                            lastName
                            email
                            isActive
                            config
                            groups {
                                id
                                name
                                permission {
                                    edges {
                                        node {
                                            id
                                            name
                                            description
                                            isActive
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`, variables: obj
    })
}
export const setClientPassword = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation SetClientPassword($email:String!, $password:String!) {
                    setClientPassword(email: $email, password: $password) {
                        success
                        error
                    }
                }`, variables: obj
    })
}
export const createAppVersionAdmin = (obj) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `mutation CreateAppVersion(
                    $versionAndroid:String!
                    $versionIos:String!
                    $linkStore:String!
                    $linkApple:String!
                ) {
                    createAppVersionAdmin(
                        versionAndroid: $versionAndroid
                        versionIos: $versionIos
                        linkStore: $linkStore
                        linkApple: $linkApple
                    ) {
                        success
                        error
                    }
                }`, variables: obj
    })
}
export const allAppVersions = (name) => {
    return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL, {
        query: `query AllAppVersions($name:String!) {
                    allAppVersions(name:$name) {
                         id
                        oldVersionAndroid
                        newVersionAndroid
                        oldVersionIos
                        newVersionIos
                        linkAndroid
                        linkIos
                        date
                    }
                }`,
        variables: {name: name}
    })
}
